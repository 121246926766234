/* Calendar */

.calendar-container		{
	padding: 0 1px 0 0;
	
	.view-only {
		padding: 0;
	}
	
	.chevron-nav {
		height: 39px;
		width: 39px;
	}
	
	.chevron-button {
		height: 39px;
		width: 39px;
		margin: 0 0 0 10px;
		border: 1px solid $medium-gray;
		text-align: center;
	}
	
	select {
		margin: 0;
	}
	
}

.calendar-wrap		{
 border-left: 1px solid $medium-gray;
}

table.calendar		{
 //border-left:  1px solid $medium-gray;
}

tr.calendar-row	{

}

td.calendar-day	{
 min-height: 80px;
 font-size: 11px;
 position: relative;
}

* html div.calendar-day {
 height: 80px;
}

td.calendar-day:hover	{
 background: $light-gray;
}

td.calendar-day-np	{
 background: #eee;
 min-height: 80px;
}

* html div.calendar-day-np {
 height: 80px;
}

td.calendar-day-head {
 background: scale-color($medium-gray, $lightness: -20%);
 font-size: 13px;
 font-weight: bold;
 text-align: center;
 width: 120px;
 padding: 5px;
 border: 1px solid scale-color($medium-gray, $lightness: -20%);
 border-right: 1px solid $medium-gray;
}

div.day-number		{
 background: $medium-gray;
 padding: 5px;
 color: $white;
 font-weight: bold;
 float: right;
 margin: -5px -5px 0 0;
 width: 20px;
 text-align: center;
}

/* shared */
td.calendar-day, td.calendar-day-np {
 width: 120px;
 padding: 5px;
 border-bottom: 1px solid $medium-gray;
 border-right: 1px solid $medium-gray;
}

i.indicator		{
	position: absolute;
	right: 24px;
	top: 8px;
	font-size: 16px;
	color: $alert-color;
}
	

td.calendar-day.valid	{
	
div.day-number		{
 background: $success-color;
}

i.indicator		{
	color: $success-color;
}

}

td.calendar-day.invalid	{
	
div.day-number		{
 background: $alert-color;
}

i.indicator		{
	color: $alert-color;
}

}

td.calendar-day.visited	{
	

}

