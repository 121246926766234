@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "../fonts/icomoon/" !default;
//$slick-font-family: "slick" !default;
$slick-font-family: "icomoon" !default;
$slick-loader-path: "../img/" !default;
//$slick-arrow-color: black !default;
$slick-arrow-color: $medium-gray !default;
//$slick-arrow-size: 20px !default;
$slick-arrow-size: 40px !default;
//$slick-dot-color: black !default;
$slick-dot-color: $medium-gray !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\e907" !default;
$slick-next-character: "\e908" !default;
$slick-dot-character: "\e911" !default;
$slick-dot-size: 12px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	}
	@else {
		@return url($slick-loader-path + $url);
	}
}

@function slick-font-url($url) {
	@if function-exists(font-url) {
		@return font-url($url);
	}
	@else {
		@return url($slick-font-path + $url);
	}
}

/* Slider */

.slick-list {
	.slick-loading & {
		background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
	}
}

/* Icons */
@if $slick-font-family == "slick" {
	@font-face {
		font-family: "slick";
		src: slick-font-url("slick.eot");
		src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
		font-weight: normal;
		font-style: normal;
	}
}

/* Arrows */

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	height: $slick-arrow-size;
	width: $slick-arrow-size;
	line-height: 0px;
	font-size: 0px;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);	
	padding: 0;
	border: none;
	outline: none;
	&:hover, &:focus {
		outline: none;
		background: transparent;
		color: transparent;
		&:before {
			opacity: $slick-opacity-on-hover;
		}
	}
	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}
	&:before {
		font-family: $slick-font-family;
		font-size: $slick-arrow-size;
		line-height: 1;
		color: $slick-arrow-color;
		opacity: $slick-opacity-default;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

.slick-prev {
	//left: -25px;
	left: $slick-arrow-size*-1.5;
	[dir="rtl"] & {
		left: auto;
		right: -25px;
	}
	&:before {
		content: $slick-prev-character;
		[dir="rtl"] & {
			content: $slick-next-character;
		}
	}
}

.slick-next {
	//right: -25px;
	right: $slick-arrow-size*-1.5;
	[dir="rtl"] & {
		left: -25px;
		right: auto;
	}
	&:before {
		content: $slick-next-character;
		[dir="rtl"] & {
			content: $slick-prev-character;
		}
	}
}

/* Dots */

.slick-dotted.slick-slider {
   margin-bottom: 30px;
}

.slick-dots {
	position: absolute;
	//bottom: -25px;
	bottom: -30px;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
	li {
		position: relative;
		display: inline-block;
		height: 20px;
		width: 20px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;
		button {
			border: 0;
			background: transparent;
			display: block;
			height: 20px;
			width: 20px;
			outline: none;
			line-height: 0px;
			font-size: 0px;
			color: transparent;
			padding: 5px;
			cursor: pointer;
			&:hover, &:focus {
				outline: none;
				&:before {
					opacity: $slick-opacity-on-hover;
				}
			}
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				content: $slick-dot-character;
				width: 20px;
				height: 20px;
				font-family: $slick-font-family;
				font-size: $slick-dot-size;
				line-height: 20px;
				text-align: center;
				color: $slick-dot-color;
				opacity: $slick-opacity-not-active;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}
		&.slick-active button:before {
			color: $slick-dot-color-active;
			opacity: $slick-opacity-default;
		}
	}
}
