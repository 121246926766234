// Cookies EU Banner
// -----------------

.cookies-eu-revoke {
    white-space: nowrap;
    text-decoration: underline;
    color: #8EA59F;
    cursor: pointer;
}

.cookies-eu-banner {
	overflow-y: auto;
	position: fixed;
	//top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
	opacity: 0;
	background: rgba(0, 0, 0, 0.8);
	padding-left: 5px;
	padding-right: 5px;
	transition: opacity 0.5s;
 
	.cookies-alignment {
		min-height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&.is-active {
		z-index: 200;
		opacity: 1;
	}
 
	.cookies-panel {
		margin-top: 5px;
		margin-bottom: 5px;
		background: white;
		width: 100%;
		//max-width: 900px;
		//padding: 20px 10px;
		border-radius: 4px;
        padding: 20px;
        display: flex;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
        }

		@media screen and (min-width: 769px) {
			padding: 40px 20px;
		}

	}
	
	.cookies-text {
        margin-right: 40px;
        font-size: 14px;
		
		h3 {
			
		}
		
		p {
			margin: 0;
		}
		
		a {
			text-decoration: underline;
			color: #b0976f;
			transition: color 0.3s;
			
			/* &:hover {
				color: red;
			} */
			
		}
		
	}
	
	.cookies-details {
		
		.accordion-item {
			
		}
		
		.accordion-title {
			padding: 0;
			background: white;
			border: none;
			padding: 5px 0 5px 16px;
			font-size: 16px;
			color: black;
			transition: color 0.3s;
			
			&:hover {
				color: red;
			}
			
			&::before {
				left: 0;
				right: auto;
				top: 45%;
			}		

		}

		.accordion-content {
			padding: 30px 0 5px 0;
			border: none;
			background: white;
		}
		
	}
	
	.cookies-checkboxes {
		display: flex;
		flex-wrap: wrap;
		margin: 20px 0;

        display: none;
		
		.cookies-checkbox {
			min-width: 260px;
			flex: 1;
			
			button {
				position: relative;
				background: none;
				padding: 10px 25px;
				cursor: pointer;
				
				&:before {
					position: absolute;
					top: 8px;
					left: 0px;
					content: "";
					border: 1px solid black;
					width: 20px;
					height: 20px;
				}
		
				&:after {
					position: absolute;
					top: 8px;
					left: 3px;
					font-size: 20px;
					content: "\2713";
					display: none;
				}
				
				&:disabled {
					color: gray;
				}
			 
				&.consented {

					&:after {
						display: block;
					}
					
				}
			 
			}
			
		}
		
	}
	
	.cookies-buttons {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		
		.cookies-save {
			margin: 20px auto;
			padding: 0 20px;
			
			a {
				color: black;
				transition: color 0.3s;
				
				&:hover {
					color: red;
				}
				
				&:active {
					
				}
				
			}
			
		}
		
		.cookies-save-all {
			font-size: 16px;
			margin: 0 auto;
			
			a {
				margin: 0;
				
				&:hover {
				
				}
				
				&:active {
					
				}
				
			}
			
		}
		
		.cookies-save-minimum {
			font-size: 16px;
			margin: 0 auto;
			
			a {
				
				&:hover {
				
				}
				
				&:active {
					
				}
				
			}
			
		}
		
	}

}

@include breakpoint(xsmall) {
	
	.cookies-eu-banner {
	
		.cookies-panel {
			//padding: 20px;
		}
		
	}

}

@include breakpoint(medium) {
	
	.cookies-eu-banner {
	
		.cookies-panel {
			//padding: 35px 70px;
		}
		
		.cookies-details {
			
			.accordion-title {
				font-size: 16px;
			}
			
		}
		
		.cookies-buttons {
			
			.cookies-save {
				margin: 20px 0;
				order: 2;
			}
			
			.cookies-save-all {
				//margin: 0 20px 0 0;
				margin: 0;
				order: 1;
			}
			
			.cookies-save-minimum {
				margin: 0 20px 0 0;
				order: 3;
			}
			
		}

	}

}

/* @include breakpoint(large) {
	
	.cookies-eu-banner {
		right: -15px;
	
		.cookies-panel {
			padding: 50px 100px;
		}

	}

} */