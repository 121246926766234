// -------------------------------------------------- navigation

.main-menu-wrapper {
    position: relative;
    display: none;
    height: 100px;
    width: 100%;
    background-color: #E8EDEC;
    z-index: 101;

    @media screen and (min-width: 1200px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}

.main-menu.menu.vertical {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 60px;
    height: 60px;
    width: fit-content;
    padding: 0 16px;
    background-color: #E8EDEC;

    a {
        font-size: 20px;
        color: #1D4B40;
        background-color: transparent;
        padding: 0;
        z-index: 2;
    }
}

.vertical.menu.submenu.is-dropdown-submenu.first-sub {
    top: calc(100% + 8px);
    left: 50% !important;
    right: unset !important;
    transform: translateX(-50%);
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    background-color: #E8EDEC;

    &.js-dropdown-active {
        display: flex;
    }

    a {
        white-space: nowrap;

        &:hover {
            color: #1779ba;
        }
    }
}

.nav__submenu {
    position: relative;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 28px;
    height: 100px;
    width: 100%;
    padding: 0 16px;
    margin: 0;
    background-color: #1D4B40;
    z-index: 1;

    @media screen and (min-width: 1200px) {
        justify-content: flex-end;
        height: 40px;
    }

    li {
        list-style-type: none;
        a {
            color: #8EA59F;
            font-size: 13px;

            &:hover {
                color: #64b8f1;
            }
        }

    }
}

.nav__submenu__pattern {
    position: absolute;
    top: -21px;

    @media screen and (min-width: 1200px) {
        display: none;
    }
}

.nav__submenu__link__text {
    margin-bottom: 0;
}

.main-nav__mobile-button {
    position: absolute;
    top: 32px;
    right: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    color: #C3B492;
    font-weight: 900;
    font-style: italic;
    z-index: 2;

    cursor: pointer;

    line {
        stroke: #C3B492;
    }

    @media screen and (min-width: 1200px) {
        display: none;
    }

    p {
        margin-bottom: 0;
    }

    &.--white {
        color: white;
        
        line {
            stroke: white;
        }
    }
}

.__mobile--open {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    gap: 0px;
    height: 100vh;
    width: 100vw;
    padding: 87px 0 0;
    
    .main-menu.menu.vertical {
        display: flex;
        flex-direction: column;
        gap: 0px;
        height: fit-content;
        width: 100%;
        padding: 0 16px;
        margin-bottom: auto;

        > li {
            position: relative;
            display: flex;
            align-items: center;
            height: 74px;
            
            border-top: 1px solid #C3B492;
            
            &:last-of-type {
                border-bottom: 1px solid #C3B492;
            }
            
            button {
                position: relative;
                width: 100%;
                z-index: 1;
                
                &::after {
                    display: none;
                }
            }
            
            a {
                max-width: 50%;
                margin: 0;
            }


        }
    }    

    .is-accordion-submenu-parent.has-submenu-toggle {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: -1px;
            left: -16px;
            height: 76px;
            width: 100vw;
            background-color: #D2DBD9;
            opacity: 0;
            transition: 250ms ease-in-out;
        }

        &:has(.is-active) {
            &::before {
                opacity: 1;
            }
        }
    }

    .vertical.menu.submenu.is-accordion-submenu {
        position: relative;
    
        &.is-active {
            z-index: 3;
        }
    }
} 

@media screen and (max-width: 1200px) {
    .main-menu-wrapper {
        .is-accordion-submenu-parent.has-submenu-toggle {
            @media screen and (max-height: 667px) {
                &:last-of-type {
                    .vertical.menu.submenu.is-accordion-submenu {
                        top: unset;
                        bottom: 100%;
                    }
                }
            }
        } 

        .vertical.menu.submenu.is-accordion-submenu {
            position: absolute;
            right: 0;
            top: 100%;
            display: flex;
            flex-direction: column;
            gap: 24px;
            min-width: 188px;
            background-color: #D2DBD9;
            padding: 32px 16px 16px;
            margin-right: -16px;
            z-index: 1;



            a {
                background-color: #D2DBD9;
            }
        }
    }
}


.main-nav__mobile-close-button {
    position: absolute;
    top: 32px;
    right: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    color: #1D4B40;
    font-weight: 900;
    font-style: italic;

    @media screen and (min-width: 1200px) {
        display: none;
    }

    p {
        margin-bottom: 0;
    }
}

.slick-slide {
    overflow: hidden;
    
    .image-container {
        img {
            object-fit: cover;
        }
    }
    
}

// -------------------------------------------------- startpage hero

.slick-slide img {
    height: 100vh !important;
    max-width: unset !important;
    margin-left: 50%;
    transform: translateX(-50%);

    @media screen and (min-width: 992px) {
        height: 100% !important;
        max-width: unset !important;
        margin-left: 0;
        transform: translateX(0);
    }
}

.section-hero {

    @media screen and (min-width: 992px) {
        margin-top: -100px;
        height: calc(100vh - 200px) !important;
        display: block;
    }

    .fp-tableCell .caption {
        position: absolute;
        top: calc(50% - 140px);
        transform: translateY(-50%);
        width: 100%;

        @media screen and (min-width: 992px) {
            top: 50%;
        }

        .top {
            position: relative;
            // top: 100px;

            img {
                height: fit-content;
            }
        }

        .main.text {
            padding: 32px 0 0 0;


            @media screen and (min-width: 992px) {
                padding: 100px 0;
            }
        }

        .icon {
            height: 188px;
            width: 188px;
        }
        img {
            position: relative;
            height: 50vh;
            display: block;
            width: auto;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .fp-tableCell .slick-slider .slick-arrow {
        display: none !important;
        transform: translateY(-130px);

        @media screen and (min-width: 992px) {
            display: unset !important;
        }
    }

    &.editmode {
        margin-top: 0px;

        .pimcore_editable.pimcore_tag_textarea {
            background-color: rgba(168, 168, 168, 0.4);
        }
    }
}

.startpage-hero__bottom-menu {
    display: grid;
    grid-template-columns: 50% 50%;
    height: 301px;
    width: 100%;
    background-color: #EDE8DE;
    
    position: absolute;
    bottom: 0;
    
    @media screen and (min-width: 992px) {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 80px;
        height: 110px;
        padding: 32px 16px;
    }
}

.startpage-hero__link {
    color: #B0976F;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
    border-right: 1px solid #B0976F;
    border-bottom: 1px solid #B0976F;
    
    @media screen and (min-width: 992px) {
        flex-direction: row;
        gap: 24px;
        border-right: none;
        border-bottom: none;
        width: fit-content;
    }

    &:hover {
        color: #1D4B40;
    }

    &.--plan {
        cursor: pointer;
    }
}

.startpage-hero__icon {
    height: 50px;
    width: 50px;
}

.startpage-hero__title {
    font-size: 40px;

    @media screen and (min-width: 992px) {
        font-size: 60px;
    }
}

.startpage-hero__text {
    margin-bottom: 0px !important;
}

.startpage-hero__map {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #1D4B40;
    z-index: 4;

    &.--open {
        display: flex;
        animation: fadeIn 250ms ease-in-out forwards;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.startpage-hero__map__close-icon {
    position: absolute;
    top: 32px;
    right: 64px;
    cursor: pointer;
}

.startpage-stopper {
    position: absolute;
    right: -48px;
    top: 32px;
    transform: rotate(25deg);
    width: 592px;
    background-color: white;
    padding: 16px 40px 16px 168px;
    
    h2 {
        font-family: "Merriweather", TimesNewRoman, Times, Baskerville, Georgia, serif;
        font-size: 1rem;
        line-height: 1.6;
        text-transform: unset;
        font-weight: 600;
        letter-spacing: normal;
        margin-bottom: 0;
        padding-bottom: 4px;
    }
    p {
        margin-bottom: 0;
    }

    @media screen and (min-width: 576px) {
        right: -56px;
        top: 40px;
        width: 600px;
        padding: 16px 48px 16px 152px;
    }
    @media screen and (min-width: 992px) {
        right: -56px;
        top: 144px;
        width: 600px;
        padding: 16px 16px 16px 104px;
    }
}

// -------------------------------------------------- editable

// .pimcore_editable.pimcore_tag_textarea {
//     color: rgb(226, 226, 226);
// }
// .pimcore_wysiwyg.cke_editable.cke_editable_inline.cke_contents_ltr.cke_show_borders {
//     color: rgb(226, 226, 226);
// }

.pimcore_editable.pimcore_tag_image {
    width: 100% !important;
    height: 100% !important;
}

@media screen and (min-width: 64em) and (min-height: 700px) {
    .section.editmode {
        height: 100vh;
        position: relative;
    }

    .section.editmode.fp-auto-height-responsive {
        height: auto;
    }
}

// -------------------------------------------------- startpage teaser

.section-startpage-teaser {
    height: fit-content !important;
    background-color: #C3B492;

    > div {
        height: fit-content !important;
        width: fit-content;
        margin: 0 auto; 
        padding: 32px 0;
        display: flex;
        flex-direction: column;
        gap: 32px;

        @media screen and (min-width: 576px) {
            display: grid;
            grid-template-columns: auto auto;
            padding: 100px 0;
            grid-gap: 32px;
        }

        @media screen and (min-width: 1200px) {
            display: grid;
            grid-template-columns: 583px 583px;
            padding: 100px 0;
            grid-gap: 32px;
        }

        &.editmode {
            width: 100%;
            margin: none;
            padding: unset;
            display: block;
            background-color: #fefefe;

            .cell.toggler-title {
                text-align: start;
            }
            .toggler-content-panel {
                text-align: start;
            }

        }
    }

    i.icon {
        &:before {
            color: #1D4B40;
        }
    }
}

.startpage-teaser__container-wrapper {
    position: relative;
    color: unset;
    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     height: 100%;
    //     width: 100%;
    //     box-shadow: 0px 0px 16px 4px #00000040;
    //     opacity: 0;
    //     transition: opacity 250ms ease-in-out;
    // }
    &:hover {
        color: unset;
        // &::before {
        //     opacity: 1;
        // }

        .button {
            background-color: #1D4B40;
        }
    }
}

.startpage-teaser__container {
    display: flex;
    flex-direction: column;
    padding: 0 32px;

    @media screen and (min-width: 1200px) {
        flex-direction: row;
        padding: 0;
    }
}

.startpage-teaser__image {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 280px;
    max-height: 280px;
    height: 280px;
    width: 100%;

    @media screen and (min-width: 1200px) {
        min-height: 418px;
        max-height: 418px;
        height: 418px;
        width: 291px;
    }

    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}

.startpage-teaser__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: 100%;
    width: 100%;
    padding: 16px 24px 32px 24px;
    background-color: white;
    
    @media screen and (min-width: 1200px) {
        justify-content: center;
        gap: 22px;
        height: 418px;
        width: 291px;
    }
}

.startpage-teaser__pattern {
    height: 24px;
    width: 24px;
    background-image: url("../img/pattern2.png");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: center;

    @media screen and (min-width: 1200px) {
        height: 30px;
        width: 30px;
        background-size: 30px 30px;
    }
}

.startpage-teaser__title {
    font-family: "Bebas Neue", Helvetica, Roboto, Arial, sans-serif;
    width: 100%;
    font-size: 40px;
    line-height: 40px;
    text-transform: uppercase;
    text-align: center;
}

.startpage-teaser__subtitle {
    width: 100%;
    font-size: 16px;
    font-style: italic;
    padding: 10px 0;
    border-top: 1px solid #1D4B40;
    border-bottom: 1px solid #1D4B40;

    @media screen and (min-width: 1200px) {
        padding: 22px 0;
    }
}

.startpage-teaser__link {
    position: relative;

    .button {
        margin: 0;
    }

    @media screen and (min-width: 576px) {
        margin: 0 0 -33px 0
    }

    @media screen and (min-width: 1200px) {
        position: absolute;
        bottom: -1px;
        margin: 0;
    }
}


// -------------------------------------------------- startpage introtext

.section-startpage-introtext {
    &.editmode {
        .cell.toggler-title {
            text-align: start;
        }
        .toggler-content-panel {
            text-align: start;
        }

    }
}

.section-startpage-introtext, .fp-tableCell {
    height: fit-content !important;
}

.startpage-introtext__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    max-width: 1088px;
    padding: 48px 32px;
    margin: 0 auto;

    @media screen and (min-width: 992px) {
        padding: 100px;
        gap: 60px;
    }
}

.startpage-introtext__title {
    font-family: "Bebas Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 40px;
    line-height: 40px;
    font-size: 30px;
    line-height: 30px;
    color: #B0976F;
    text-align: start;
    min-width: 100%;
    padding-right: 0;

    @media screen and (min-width: 992px) {
        font-size: 40px;
        line-height: 40px;
        max-width: 495px;
    }
}
.startpage-introtext__text {
    color: #B0976F;
    font-size: 14px;
    font-style: italic;
    text-align: start;
    width: 100%;
    padding-left: 0;

    @media screen and (min-width: 992px) {
        font-size: 20px;
        line-height: 30px;
        max-width: 786px;
        margin: 0 auto;
    }

    strong {
        // font-family: "Bebas Neue", Helvetica, Roboto, Arial, sans-serif;
        // font-size: 20px;
        font-size: 16px;
        line-height: 20px;
        font-style: normal;

        @media screen and (min-width: 992px) {
            // font-size: 30px;
            font-size: 24px;
            line-height: 30px;
        }
    }

    p {
        margin: 4px;
    }
}


// -------------------------------------------------- startpage testimonials

.section-startpage-testimonials {
    background-color: #1D4B40;
    margin: 0 auto;
    height: fit-content !important;


    .fp-tableCell {
        position: relative;
        height: fit-content !important;
        padding-bottom: 50px;
        z-index: 1;

        @media screen and (min-width: 992px) {
            padding-bottom: 100px;
        }

        .slick-arrow {
            margin: -16px 0 0 0;

            @media screen and (min-width: 992px) {
                margin: 0;
            }
        }

    }

    &.editmode {
        .editmode.grid-x {
            background-color: #fefefe;
        }

        .cell.toggler-title {
            text-align: start;
        }
        .toggler-content-panel {
            text-align: start;
        }

    }
}

.startpage-testimonial__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: calc(786px + 32px*2);
    padding: 50px 32px 20px;
    text-align: start;

    @media screen and (min-width: 992px) {
        padding: 100px 32px 40px;
    }
}

.startpage-testimonial__text {
    width: 100%;
    color: white;

    p {
        color: white;
        font-style: italic;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 12px;

        @media screen and (min-width: 992px) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    div {
        display: none;
    }
}

.startpage-testimonial__link {
    width: 100%;
    color: white;
    font-style: italic;
    font-size: 13px;
    line-height: 30px;
    text-decoration: underline;

    @media screen and (min-width: 992px) {
        font-size: 14px;
        line-height: 30px;
    }
}

.startpage-testimonial__pattern {
    position: absolute;
    left: 50%;
    bottom: -16px;
    height: 32px;
    width: 32px;
    background-image: url("../img/pattern3.png");
    background-repeat: no-repeat;
    background-size: 32px 32px;
    background-position: center;
    transform: translateX(-50%);
    z-index: 100;

    @media screen and (min-width: 992px) {
        height: 42px;
        width: 42px;
        background-size: 42px 42px;
    }
}


// -------------------------------------------------- startpage events

.section-startpage-events {
    height: fit-content !important;
    overflow: visible;

    &.section {
        text-align: start;
    }

    .fp-tableCell {
        @media screen and (min-width: 992px) {
        }

        .has-ornament:before {
            width: 42px;
            height: 42px;
            top: 0px;
        }
    }
}

.startpage-events__title {
    margin-bottom: 20px;

    @media screen and (min-width: 992px) {
        margin-bottom: 50px;
    }
}


