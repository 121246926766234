//	Schlossrued Stylesheet
//	-----------------------------
//
//	Table of Contents:
//
// 	1. Global
// 	2. UI/Font Sizes
//  3. Components
//  4. Header
//  5. Off Canvas, Map Container
//  6. Navigation
//  7. Image Cover
//  8. Fullpage js Grid
//  9. Fullpage js Content
// 10. Section Banner
// 11. Isotope/Masonry
// 12. Section Content
// 13. Section Features
// 14. People Item
// 15. Teaser Footer Grid
// 16. Teaser Footer Content
// 17. Section Left Right Grid
// 18. Section Left Right Content
// 19. Section Booking
// 20. Nav Progress
// 21. Veranstaltugen
// 29. Footer
// 30. Editmode
 
// 1. Global
//	-----------------------------

$hero-top: 75;
$hero-bottom: 100 - $hero-top;
$arrow-width: 85;
$cWS: 800;
$cHS: 700;
$cWM: 1200;
$cHM: 750;
$cWL: 2000;
$cHL: 1200;
$cHLhero: $cHL*$hero-top/100;
$reponsiveHeight: 700px;
$transition-speed: 0.5s;
$button-text-width: 120px;
$main-menu-width: 360;
$icon-size: 100px;
$people-item-size: 250px;
$people-img-size: 180px;
$ornament-1-size: 40px;

// set base font size to 16px
html { 
	font-size: 16px;
	-webkit-text-size-adjust: 16px; 
	-ms-text-size-adjust: 16px; 
	//-webkit-font-smoothing: antialiased;
	//-moz-osx-font-smoothing: grayscale;
}

// Template Remove A11Y Outlines
* { 
	outline:none; 
}

//prevent the blue highlighting of elements when clicking
.noSelect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.no-padding {
	padding: 0;
}

.no-padding-x {
	padding-left: 0;
	padding-right: 0;
}

.hidden {
	visibility:hidden;
	width:0px!important;
	height:0px!important;
	margin:0px!important;
	padding:0px!important;
	overflow: hidden!important;
}

a.cell {
	display: block;
}

//page-grid
.page-wrapper {
	width: 100%;
	max-width: rem-calc(2000);
	margin: 0 auto;
}

.image {
	
	img {
		min-width: 100%;
	}
	
}

.flex-video {
	margin: 0;
}

.click {
	transition: all 0.3s ease-in-out;
	
	&.is-clicked {
		transform: translateY(6px);
	}
	
}

//stopper

.section {
	position: relative;
	overflow: hidden;
}

.stopper {
	position: absolute;
	background: $white;
	width: 300px;
	padding: 10px 10px;
	right: -50px;
	top: 30px;
	transform: rotate(28deg);
	z-index: 10;
	
	p {
		margin: 0;
		font-size: rem-calc(9);
		font-style: italic;
		color: #1d4b40;
	}
	
}

@include breakpoint(xsmall) { 

	.stopper {
		width: 400px;
		padding: 10px 40px;
		right: -80px;
		top: 40px;
		
		p {
			font-size: rem-calc(11);
		}
	
	}

}	

@include breakpoint(medium) { 

	.stopper {
		width: 500px;
		padding: 12px 80px;
		right: -120px;
		top: 50px;
		
		p {
			font-size: rem-calc(12);
		}
	
	}

}

@include breakpoint(large) { 

	.stopper {
		width: 600px;
		padding: 15px 80px;
		right: -150px;
		top: 45px;
		
		p {
			font-size: rem-calc(14);
		}
	
	}

}


@include breakpoint(xlarge) { 

		.stopper {
		width: 700px;
		padding: 15px 80px;
		right: -180px;
		top: 50px;
		
		p {
			font-size: rem-calc(16);
		}
	
	}

}
  
  
//call out
  
.call-out {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	width: 100%;
	max-width: 500px;
	padding: 20px 20px;
	background: rgba($dark-green, 0.8);
	z-index: 100;
	color: $white;
	
	&.is-top {
		top: 100px;
		left: 50%;
		transform: translate(-50%, 0);
	}
	
	p {
		margin: 0;
		font-style: italic;
	}
	
	.large {
		
		p {
			font-size: rem-calc(25);
			font-style: italic;
			margin: 20px 0;
		}
	
	}

	.close-button {
		color: $white;
		width: 120px;
		
		&:hover {
			color: $white;
		}

		.text {
			margin: 3px 5px 0 0;
			font-size: rem-calc(14);
			font-style: italic;
			font-weight: 600;
			text-align: right;
		}
		
		.cross {
			font-size: rem-calc(40);
			font-weight: 300;
		}
	
	}

}

@include breakpoint(xlarge) { 
	
	.call-out {
		padding: 60px 60px;
	}

}

// 2. Font Sizes
//	-----------------------------

h1, h2 {
	font-family: $bebas-font-family;
	letter-spacing: 1px;
	line-height: 1.1;
	text-transform: uppercase;
}

h1 {
 font-size: rem-calc(27);
 letter-spacing: 2px;
}

h2 {
	font-size: rem-calc(22);
 letter-spacing: 1px;
}

h3 {
	margin: 0 0 30px 0;
}
	
p {
	line-height: 1.6;
}		

.section-content {
	
	.text {
		
		h3 {
			font-size: rem-calc(20);
		}
		
		p, li {
			font-size: rem-calc(15);
		}
		
		a {
 		color: $body-font-color;
 		font-weight: bold;
		}
	
	}

}

.teaser-card {

	.front {
	
		h4 {
			font-family: $bebas-font-family;
			text-transform: uppercase;
	 	font-size: rem-calc(20);
	 	line-height: 1.1;
	 	letter-spacing: 1px;
		}
		
		p {
			font-size: rem-calc(11);
			font-style: italic;
			display: block;
			margin: 0;
		}
		
		.icon {
 		font-size: rem-calc(10);
		}
			
	}
	
}

.room-list, .packages-list {
	
	.teaser-card {
	
			.front {
			
				p {
					font-style: normal;
				}
				
				.icon {
		 		font-size: rem-calc(18);
				}
					
			}
			
			.back {
				
					h2 {
					font-size: rem-calc(50);
				}

			}
			
		}

}		

.packages-list {
	
	.teaser-card {
	
			.front {
						
				.icon {
		 		font-size: rem-calc(30);
				}
					
			}
			
		}

}		

@include breakpoint(xsmall) { 

	h1 {
	 font-size: rem-calc(30);
	 letter-spacing: 2px;
	}

	h2 {
		font-size: rem-calc(27);
	 letter-spacing: 2px;
	}

 .section.hero {
  
	  h1 {
		  font-size: rem-calc(35);
	   letter-spacing: 2px;
	  }
  
		}

	.teaser-card {
	
		.front {
	
			h4 {
	  	font-size: rem-calc(22);
			}
			
			p {
  		font-size: rem-calc(14);
				margin: 0 0 10px 0;
			}
			
			.icon {
  		font-size: rem-calc(14);
			}
			
		}
	
	}

}


@include breakpoint(medium) { 

	h1 {
	 font-size: rem-calc(35);
	 letter-spacing: 2px;
	}

	h2 {
		font-size: rem-calc(30);
	 letter-spacing: 2px;
	}

 .section.hero {
  
	  h1 {
		  font-size: rem-calc(40);
	   letter-spacing: 2px;
	  }
  
		}

	.section-content {
		
		.text {
			
			h3 {
				font-size: rem-calc(25);
			}
			
			p, li {
				font-size: rem-calc(16);
			}
		
		}
	
	}

}

@include breakpoint(xmedium) { 

	h1 {
	 font-size: rem-calc(36);
	 letter-spacing: 2px;
	}

	h2 {
		font-size: rem-calc(32);
	 letter-spacing: 2px;
	}

 .section.hero {
  
	  h1 {
		  font-size: rem-calc(45);
	   letter-spacing: 3px;
	  }
  
		}

	.teaser-card {
	
		.front {
	
			h4 {
	  	font-size: rem-calc(23);
			}
			
			p {
  		font-size: rem-calc(14);
			}
			
		}
	
	}

}

.room-list, .packages-list {
	
	.teaser-card {
	
		.front {
		
			h4 {
	  	font-size: rem-calc(28);
			}

		}
		
		.back {
			
			h2 {
	  	font-size: rem-calc(60);
			}
		
		}

	}

}		

@include breakpoint(large) { 

	h1 {
	 font-size: rem-calc(45);
	 letter-spacing: 4px;
	}

	h2 {
		font-size: rem-calc(35);
	 letter-spacing: 2px;
	}

 .section.hero {
  
  h1 {
	  font-size: rem-calc(50);
   letter-spacing: 4px;
  }
 
	}

	.teaser-card {
	
		.front {
	
			h4 {
	  	font-size: rem-calc(24);
	  	letter-spacing: 2px;
			}
			
			p {
  		font-size: rem-calc(14);
			}
			
		}
	
	}
	
}

@include breakpoint(xlarge) { 

	h1 {
	 font-size: rem-calc(55);
	 letter-spacing: 4px;
	}

	h2 {
		font-size: rem-calc(40);
	 letter-spacing: 3px;
	}

		
 .section.hero {
  
   h1 {
 	  font-size: rem-calc(60);
    letter-spacing: 4px;
   }
  
		}

	.teaser-card {
	
		.front {
	
			h4 {
	  	font-size: rem-calc(26);
	  	letter-spacing: 2px;
			}
			
			p {
  		font-size: rem-calc(14);
			}
			
			.icon {
  		font-size: rem-calc(16);
			}
			
		}
	
	}

	.room-list, .packages-list {
		
		.teaser-card {
		
				.front {
				
					h4 {
			  	font-size: rem-calc(36);
					}
	
				}
				
			}
	
	}		

}

	
// 3. Components
//	-----------------------------

//mixins

@mixin icomoon {
 font-family: 'icomoon' !important;
 speak: none;
 font-style: normal;
 font-weight: normal;
 font-variant: normal;
 text-transform: none;
 line-height: 1;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}

@mixin gradientgold {
	background: $dark-gold;
	background: -moz-linear-gradient(45deg, $dark-gold 0%, $light-gold 100%);
	background: -webkit-linear-gradient(45deg, $dark-gold 0%,$light-gold 100%);
	background: linear-gradient(45deg, $dark-gold 0%,$light-gold 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$dark-gold', endColorstr='$light-gold',GradientType=1 );
}

@mixin gradientgreen {
	background: $dark-green;
	background: -moz-linear-gradient(45deg, $dark-green 0%, $light-green 100%);
	background: -webkit-linear-gradient(45deg, $dark-green 0%,$light-green 100%);
	background: linear-gradient(45deg, $dark-green 0%,$light-green 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$dark-green', endColorstr='$light-green',GradientType=1 );
}

//Backgrounds

.bg-light-stone {
	background: $light-stone;
}

.bg-medium-stone {
	background: $medium-stone;
}

.bg-light-green {
	background: $light-green;
}

.bg-medium-green {
	background: $medium-green;
}

.bg-dark-green {
	background: $dark-green;
}

.bg-medium-gold {
	background: $medium-gold;
}

.bg-light-gold {
	background: $light-gold;
}

.bg-dark-gold {
	background: $dark-gold;
}

.bg-white {
	background: $white;
}

.bg-gradient-green {
	@include gradientgreen;
}

.bg-gradient-gold {
	@include gradientgold;
}

.has-ornament {
	position: relative;
	
	&:before {
  @include icomoon;
  font-size: $ornament-1-size;
		content: '\e922';
		position: absolute;
		width: 30px;
		height: 30px;
		top: 0;
		left: 50%;
		margin: $ornament-1-size/-2 0 0 $ornament-1-size/-2;
		color: $medium-gold;
	}
	
}

//Text

.text {
	
	&.gold {
		color: $dark-gold
	}
	
}

.text {
	
	&.white {
		color: $white
	}
	
}

@include breakpoint(large) { 
	
	.text {
		
		&.lg-neg {
			color: $white
		}
		
	}
	
}

//Buttons

.button-list {
	list-style: none;
	margin: 0;
	
	li {
		margin: 0 5px;
		display: inline-block;
	}
	
	&.filters-button-group {
		text-align: center;
		
		li {
			margin: 0;
		}

		.button {
			min-width: auto;
			position: relative;
			font-style: normal;
			padding: 5px 22px;
			color: $dark-gold;
			background: $white;
			font-size: rem-calc(12);
			text-decoration: underline;
			
			&:after {
				position: absolute;
				content: "\00d7";
				font-size: rem-calc(25);
				font-weight: 300;
				top: -2px;
				right: 0;
				opacity: 0;
			}
			
			&.is-checked {
				color: $dark-green;
			}
			
			&.button-all {
				padding: 5px 22px 5px 0;
				text-decoration: none;
			}
			
			&:not(.button-all).is-checked {
				
				&:after {
					opacity: 1;
				}
				
			}

		}
		
	}

}

.button {
	min-width: 180px;
	background: $medium-gold;
	font-style: italic;
	font-size: rem-calc(14);
	
	&:hover {
		background: $dark-green;
	}
	
	&.mg-hover {
		
		&:hover {
			background: $medium-green;
		}
		
	}
	
	&.green {
		background: $dark-green;
		
		&:hover {
			background: $medium-gold;
		}
	
		&.mg-hover {
			
			&:hover {
				background: $medium-green;
			}
			
		}

	}
	
	&.white {
		background: $white;
		color: $dark-gold;
		
		&:hover {
			background: $dark-green;
			color: $white;
		}
	
	}
	
}

//teaser Button
.section-teaser-button {
	padding: 30px 0;
}

.teaser-button {
	@include gradientgold;
	display: flex;
	width: 200px;
	height: 200px;
	border-radius: 50%;
	margin: 0 auto;
	text-align: center;
	padding: 10px;
	color: $white;
	
	a {
	color: $white;
	}
	
	p {
		margin: 0 0 8px 0;
	}
	
	&:hover {
		@include gradientgreen;
		color: $white;
	}
	
	&.margin {
		margin: 30px auto;
	}
	
	&.margin-top {
		margin: 30px auto 0 auto;
	}
	
	&.margin-bottom {
		margin: 0 auto 30px auto;
	}
	
}

@include breakpoint(large) { 
	
	.text {
		
		&.lg-neg {
			color: $white
		}
		
	}

	.button {
	
		&.lg-neg {
			background: $white;
			color: $medium-gold;

			&:hover {
				background: $dark-green;
				color: $white;
			}
			
		}
		
	}

	.teaser-button {
		width: 240px;
		height: 240px;
		padding: 15px;
	}

}

// slick slider

.slick-prev, 
.slick-next {
	//border: 1px solid red;
	top: 100%;
	margin: -50px 0 0 0;
	transform: none;
	height: $icon-size*0.75/4;
	width: $icon-size*0.75;
	z-index: 1;

	&:before {
		font-size: $icon-size*0.75/5;
		color: $white;
		opacity: 1;
	}
	
}

.slick-prev {
	left: calc(50% - #{$icon-size*0.75 + 7px});
}

.slick-next {
	right: calc(50% - #{$icon-size*0.75 + 7px});
}

//fighting space under slick-slider
.slick-list {
	//border: 1px solid red;
	margin: -2px 0 -7px 0;
}
	
@include breakpoint(large) { 

	.slick-prev, 
	.slick-next {
		height: $icon-size/4;
		width: $icon-size;
	
		&:before {
			font-size: $icon-size/5;
			color: $white;
			opacity: 1;
		}
		
	}
	
	.slick-prev {
		left: calc(50% - #{$icon-size + 10px});
	}
	
	.slick-next {
		right: calc(50% - #{$icon-size + 10px});
	}

}


// 4. Header
//	-----------------------------

.breadcrumb-container {
	height: 15px;
	overflow: hidden;
}

.nav-breadcrumb {
	list-style: none;
	margin: 25px 0 0 0;
	
	li {
		display: inline-block;
		font-size: rem-calc(12);
		
		&:after {
			content: '/';
		}
		
		&:first-child {
		
			a {
				padding: 5px 10px 5px 0;
			}
		
			&:after {
				content: '';
			}
		
		}
		
		&:last-child {
		
			&:after {
				content: '';
			}
		
		}
		
		a {
			color: $dark-green;
			padding: 5px 10px;
		}
		
		&.active {
			
			a {
				color: $dark-gold;
			}
			
		}
		
	}
	
}

@include breakpoint(large) { 

	.breadcrumb-container {
		height: 50px;
	}

}

.header {
		height: 60px;
	
	.header-bg {
		position: fixed;
		height: 60px;
		width: 100%;
		z-index: 2;
		background: $white;
		-webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1);
		-moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1);
		box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1);
	}
	
}

.menu-button {
	position: fixed;
	top: 15px;
	right: 20px;
	z-index: 20;
	height: 30px;
 font-style: italic;

	.burger-icon {
		@include burger(20px, 2px, 4px, $white);
	}
	
	.menu-text {
		width: $button-text-width;
		color: $white;
		
		> div {
			position: absolute;
			top: 50%;
			right: 30px;
			transform: translateY(-50%);
			transition: opacity $transition-speed ease-in-out;
		}
		
	}
	
	.menu-text-close {
		opacity: 0;
	}
	
	.menu-text-open {
		opacity: 1;
	}

	&.is-active {
		
		.menu-text-close {
			opacity: 1;
		}
		
		.menu-text-open {
			opacity: 0;
		}
	
		.burger-icon {
			@include burger-to-cross;
		}
		
	}
	
}

.map-button {
	position: fixed;
	z-index: 30;
	top: 15px;
	left: 20px;
	height: 30px;
 font-style: italic;
	
	.map-icon {
		height: 22px;
		width: 22px;
		border: 1px solid transparent;
		border-radius: 50%;
		transition: border $transition-speed ease-in-out;
		color: $white;
		
		> div {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			transition: opacity $transition-speed ease-in-out;
		}
		
		.icon-locationcastle {
			margin: 3px 0 0 0;
			font-size: rem-calc(28); 
		}
		
		.burger-to-cross {
		 @include burger(14px, 2px, 3px, $white);
			@include burger-to-cross;
			margin: 0 0 0 3px;
			
			&::before, &::after {
    opacity: 0;
				transition: opacity $transition-speed ease-in-out;
   }
   
		}
  
	}

	.map-icon-close {
		opacity: 0;
	}
	
	.map-icon-open {
		opacity: 1;
	}

	.map-text {
		width: $button-text-width;
		color: $white;

		> div {
			position: absolute;
			top: 50%;
			left: 30px;
			transform: translateY(-50%);
			transition: opacity $transition-speed ease-in-out;
		}
		
	}
	
	.map-text-close {
		opacity: 0;
	}
	
	.map-text-open {
		opacity: 1;
	}

	&.is-active {

		.map-icon {
			border: 1px solid $white;
			
			.burger-to-cross {

				&::before, &::after {
	    opacity: 1;
	   }
	   
			}

		}
		
		.map-icon-close {
			opacity: 1;
		}
		
		.map-icon-open {
			opacity: 0;
		}

		.map-text-close {
			opacity: 1;
		}
		
		.map-text-open {
			opacity: 0;
		}
	
	}

}

.pos {
 
 .menu-button {
 
  .burger-icon {
   background-color: $dark-gold;
  
   &::before, &::after {
    background-color: $dark-gold;
   }
  
  }
  
  .menu-text {
   color: $dark-gold;
  }
  
  &.is-active {
  
   .burger-icon {
    background-color: transparent;
   
    &::before, &::after {
     background-color: $white;
    }
   
   }
  
   .menu-text {
    color: $white;
   }
 
  }
   
 }

 .map-button {
 
  .map-icon {
   color: $dark-gold;
  }
  
  .map-text {
   color: $dark-gold;
  }  
  
  &.is-active {
  
   .map-icon {
    border: 1px solid $white;
   }
   
   .map-text {
    color: $white;
   }  
  
  }
   
  &.is-pos {
   //background-color: $white; 
    
   .map-text {
  	 color: $white;
   }
  
   .map-icon {
  	 color: $white;
   }
  
  }

 }

}
@include breakpoint(xmedium down) {
	
 .menu-button {
 
  .burger-icon {
   background-color: $dark-gold;
  
   &::before, &::after {
    background-color: $dark-gold;
   }
  
  }
  
  .menu-text {
   color: $dark-gold;
  }
  
  &.is-active {
  
   .burger-icon {
    background-color: transparent;
   
    &::before, &::after {
     background-color: $white;
    }
   
   }
  
   .menu-text {
    color: $white;
   }
 
  }
   
 }

 .map-button {
 
  .map-icon {
   color: $dark-gold;
  }
  
  .map-text {
   color: $dark-gold;
  }  
  
  &.is-active {
  
   .map-icon {
    border: 1px solid $white;
   }
   
   .map-text {
    color: $white;
   }  
  
  }
   
  &.is-pos {
   //background-color: $white; 
    
   .map-text {
  	 color: $white;
   }
  
   .map-icon {
  	 color: $white;
   }
  
  }

 }

}
 

@include breakpoint(large) { 

	.header {
		height: 0;
		
		.header-bg {
			display: none;
		}
		
	}

	.menu-button {
		top: 25px;
		right: 25px;
	}

	.map-button {
		padding: 3px 20px;
		top: 50%;
		margin-top: ($button-text-width + 12px)*-1;
		left: auto;
		right: 0;
		height: 40px;
  transform: rotate(-90deg);
  transform-origin: 100% 100%;
		background: $medium-gold;

		&:hover {
			background: $dark-green;
		}
		
		.map-icon {
			color: $white;
			transform: rotate(90deg);
		}
 		
		.map-text {
			width: $button-text-width;
   color: $white;
	
			> div {
				position: absolute;
				top: 50%;
				margin: -1px 0 0 0;

				left: auto;
				right: 60px;
				transform: translateY(-50%);
				transition: opacity $transition-speed ease-in-out;
			}
			
		}
	
		&.is-active {

			.map-icon {
					border: 1px solid $white;
					
				.burger-to-cross {
					
					&::before, &::after {
		    background-color: $white;
		   }
		   
				}

			}
			
 		&:hover {
 			background: $dark-gold;
 		}
 		
		}
		
  &.is-pos {
   background-color: $white; 
    
   .map-icon {
  	 color: $dark-gold;
   }
  
   .map-text {
  	 color: $dark-gold;
   }
  
  }
		
	}
 
	
 &.pos {
 
  .map-button {
  
   .map-icon {
    color: $white;
   }
   
   .map-text {
    color: $white;
   }
  
   &.is-pos {
    background-color: $white; 
     
    .map-icon {
   	 color: $dark-gold;
    }
   
    .map-text {
   	 color: $dark-gold;
    }
   
   }

  }
   
 }
 
}

// 5. Off Canvas, Map Container
//	-----------------------------


.map-container {
	position: relative;
	color: $white;
 
	.content-panel {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		min-height: 100vh;
		z-index: -1;
		opacity: 0;
		transition: opacity $transition-speed ease-in-out;
					
 .plan img { 
  margin: 0 auto;
  display: block;
  width: 90vw;
  }

		&.is-active {
			z-index: 25;
			opacity: 2;
		}
		
	}

}

@include breakpoint(large) { 

	.off-canvas {
	
		.content-panel {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
			opacity: 0;
			transition: opacity $transition-speed ease-in-out;
						
			&.is-active {
				z-index: 2;
				opacity: 1;
			}
			
		}
	
	}

	.map-container {
	
		.content-panel {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	
	}

}

// 6. Navigation
//	-----------------------------


.navigation-wrap {
 padding: 0 10px;

	.menu .active > a {
		background: transparent;
	}
	
 .main-menu {
  margin: 70px 0 0 0;
  
  .has-submenu-toggle > a {
   margin-right: 40px; 
 	}
  
  .submenu-toggle {
   position: absolute;
   top: 0;
   right: -10px;
   cursor: pointer;
   width: 40px;
   height: 50px; 
   
    &::after {
    @include icomoon;
    content: '\e901';
    border: none;
    font-size: rem-calc(12);
    margin: -5px 0 0 8px;
    color: $dark-green;
   }
   
   &[aria-expanded='true']::after {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    content: '\e904';
   }
   
  }
  
  li {
   font-family: $bebas-font-family;
   letter-spacing: 1px;
   
   > a {
 	  background: transparent;
    padding: 15px 0;
    font-size: rem-calc(25);
    line-height: rem-calc(25);
    color: $dark-green;
   }
 
   &:hover,
   &.is-active,
   &.is-active:hover {
    
    > a {
     color: $dark-green;
    }
    
   }

   ul {
    background: transparent;
    margin: 0 0 30px 0;
    border: none;
    
    li {
     font-family: $body-font-family;
     font-style: italic;
     display: inline-block;
     width: auto;
     letter-spacing: 0px;
     
     > a {
      padding: 10px 0;
      font-size: rem-calc(16);
      line-height: rem-calc(25);
     }
     
     &:hover {
      
      > a {
       color: $white;
      }
      
     }

    }
 
   }

  }
  
 }
 
 .sub-menu {
  list-style: none;
  margin: 20px 0 0 0;
 
  li {
			font-style: italic;
			text-align: left;
			margin: 40px 0 0 0;
  
   > a {
    color: $white;
				position: relative;
 			display: block;
    padding: 0 30px 0 60px;
				
	   &:before {
	    content: '';
	    position: absolute;
	    top: 50%;
	    left: 0;
					transform: translate(0,-50%);
		   width: 50px;
		   height: 50px;
		   border-radius: 50%;
		   border: 1px solid $white;
   	}
   
	   &:after {
	    @include icomoon;
	    content: '';
	    position: absolute;
	    top: 0;
	    left: 13px;
		   width: 50px;
		   font-size: rem-calc(23);
   	}
			
   }
   
   &:hover {
	   
	   > a {
    	color: $dark-green;
    	
		   &:before {
			   border: 1px solid $dark-green;
	   	}
	   	
	   }
	   
   }
  
	  &:nth-child(1){
		  
		  > a {
			  
			  &:after {
			  font-size: rem-calc(22);
			  content: '\e931';
			  }
			  
		  }
		  
	  }      
	  
	  &:nth-child(2){
		  
		  > a {
			  
			  &:after {
			  font-size: rem-calc(25);
			  content: '\e90f';
			  }
			  
		  }
		  
	  }      
	  
	  &:nth-child(3){
		  
		  > a {
			  
			  &:after {
				  content: '\e927';
			  }
			  
		  }
		  
	  }      
	  
	  &:nth-child(4){
		  
		  > a {
			  
			  &:after {
				  content: '\e915';
			  }
			  
		  }
		  
	  }      
	  
  }
  
 }

}


@include breakpoint(medium) { 
 
 .navigation-wrap {
  padding: 0 50px;
  
  .main-menu {
    margin: 90px 0 0 0;
  }
  
 }

}

@include breakpoint(large) { 

 .navigation-wrap {
  
  .main-menu {
   max-width: $main-menu-width + px;
   margin: 0;
 
   .is-dropdown-submenu {
    width: calc(100vw - #{$main-menu-width + 130 + px});
    max-width: $global-width - rem-calc($main-menu-width + 130);
   }

   li {
    
    > a {
     padding: 20px 0;
     font-size: rem-calc(35);
     line-height: rem-calc(35);
     letter-spacing: 2px;
    }

    ul {
      
     li {
      
      > a {
       padding: 20px 20px;
       font-size: rem-calc(16);
       line-height: rem-calc(35);
       letter-spacing: 0;
      }
       
     }
  
    }
 
   }
   
  }
   
  .sub-menu {
   margin: 100px 0 0 0;
   text-align: center;
   
   li {
   	display: inline-block;
    
    > a {
     padding: 0 30px 0 60px;
    }
    
   }

  }
  
 }

}


// 7. Image Cover
//	-----------------------------

@include breakpoint(large) { 
	
	@media screen and (min-height: $reponsiveHeight) {
		
		.image-container {
		 position: relative;
			overflow: hidden;
			width: 100%;
			height: 100vh;

			img.cover {
			 position: absolute; 
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%);
				min-width: 100%;
			}
		
		}
		
		.hero {
			
			.image-container {
				height: $hero-top + vh;
				margin-bottom: $hero-bottom + vh;
			}
	
		}
	
		@media (max-aspect-ratio: #{$cWL}/#{$cHL}) {
				
				.image-container {
			
					img.cover {
						height: 100vh;
						max-width: $cWL/$cHL*100 + vw;
					}
							
				}
		
				.hero {
					
					.image-container {
	
						img.cover {
							height: $hero-top + vh;
							max-width: $cWL/$cHLhero*100 + vw;
						}
					
					}
							
			}
	
		}
		
	}

}


// 8. Fullpage js Grid
//	-------------------

.section {
	text-align: center;
	
	.slick-list {
		z-index: -1;
	}
	
	.grid-container {
 		padding: 0 30px;
	}
			
	.top {
		position: absolute;
		top: $cHS/$cWS*50 + vw;
		width: 100%;
		transform: translateY(-50%);
 	}

	.hero-text {

		a {
			color: #b0976f;
			text-decoration: underline;
		}

	}

	.button-list-hero-desktop {
		display: none;
		margin-top: 20px;

		@include breakpoint(large) {
			display: block;
		}

	}

	.button-list-hero-mobile {
		
		@include breakpoint(large) {
			display: none;
		}

	}

	.main {
		padding: 50px 0px;
		width: 100%;
	}
	
 &.hero {
 	
 	.top {
    margin: -10px 0 0 0;
 	}
 	
 	.main {
 		background: $white;
   text-align: center;
 	}
 	
 }
 
}

@include breakpoint(medium) { 

 .section {
  
 	.top {
    top: $cHM/$cWM*50 + vw;
  }
  
 }

}
	
@include breakpoint(large) { 
	
 .section {
  
		.grid-container {
	 	padding: 0 15px;
		}
			
  &.hero {
    
  	.top {
    top: $cHLhero/$cWL*50 + vw;
   }
   
  }
  
 }
 
	@media screen and (min-height: $reponsiveHeight) {
		
		.section {
			
				&.editmode {
				height: 100vh;
				position: relative;
				
				&.fp-auto-height {
					height: auto;
				}
				
			}
			.caption {
				position: absolute;
				width: 100%;
				top: 50%;
    			transform: translateY(-50%);
			}
			
			.top {
				position: relative;
				top: 0;
				transform: none;
			}
			
			.main {
				padding: 0;
			}
		
   &.hero {
 		 
 			.caption {
 				top: 0;
     transform: none;
 			}
			
   	.top {
     top: 0;
     margin: $hero-top/2 + vh 0 0 0;
     transform: translateY(-50%);
    }
 
 			.main {
 			 position: absolute; 
 			 bottom: 0;
 				height: $hero-bottom + vh;
 				padding: 0;
 			}
 			
				.slick-prev,
				.slick-next {
	 			margin-top: calc(#{$hero-bottom*-1 + vh} - 50px);
				}

 		}
 	
 	}
 	
 }
		
}

// 9. Fullpage js Content
//	----------------------

.section {
 
	.image-container {
		position: relative;
		overflow: hidden;
		
		&:after {
			position: absolute;
			content: '';
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(0,0,0,0.3);
		}
		
	}

 	.top {
 		color: $white;
 
 		.icon {
  			display: inline-block;
  			height: $icon-size*0.9;
  			width: $icon-size*0.9;
	  		margin: 0 0 20px 0;
	  
	  		img {
 	  			width: 100%;
	  		}
	  
 		}
 	
 		h1 {
  			margin: 0;
 		}
 	    
 	}
 
 	.main {
	 
	 	p {
   			font-style: italic;
  		}

		.button-list {
			margin: 30px 0 0 0;
	 	}
 	    
	}
 
 	&.hero {
  
  		.main {
		
			h2 {
				margin: 0;
			}
		
   			.large-shrink {
				padding: 0 0 20px 0;
   			}
   
  		}
  
 	}

}

.panel-button-editmmode {

	.pimcore_tag_link {

		a {
			color: white;
		}

	}
	
}

@include breakpoint(xsmall) { 

 .section {
  
  .top {
  
  	.icon {
   	height: $icon-size*1.2;
   	width: $icon-size*1.2;
 	  margin: 0 0 20px 0;
  	}
 
  }
  
 }

}

@include breakpoint(medium) { 

 .section {
  
  .top {
  
  	.icon {
   	height: $icon-size*1.35;
   	width: $icon-size*1.35;
 	  margin: 0 0 30px 0;
  	}
 
  }
  
 }

}

@include breakpoint(xmedium) { 

 .section {
  
  .top {
  
  	.icon {
   	height: $icon-size*1.5;
   	width: $icon-size*1.5;
 	  margin: 0 0 30px 0;
  	}
 
  }
  
	}

}

@include breakpoint(large) { 

 .section {
  
  .top {
  
  	.icon {
   	height: $icon-size*1.9;
   	width: $icon-size*1.9;
 	  margin: 0 0 50px 0;
  	}
 
  }
  
  .main {
	  margin: 15px 0 0 0;
  }
  
  &.hero {
   
   .main {
    margin: 0;
    
    h2 {
					margin: 0 0 2px 0;
    }

    .large-shrink {
     padding: 0 40px 0 0;
    }
    
   }
   
  }
  
 }

}

@include breakpoint(xlarge) { 

 .section {
  
  .top {
  
  	.icon {
   	height: $icon-size*2.5;
   	width: $icon-size*2.5;
 	  margin: 0 0 50px 0;
  	}
  	
  }
  
  &.hero {
   
   .main {
    
    .large-shrink {
     padding: 0 60px 0 0;
    }
    
   }
   
  }
  
 }

}

// 10. Section Banner
//	--------------------

.section-banner {
	position: relative;
	margin: $icon-size/2 0 0 0;
	min-height: $icon-size/2 + 15px;
	
	&.no-margin {
		min-height: auto;
		border: 1px solid red;
	}
	
	.slick-list {
		z-index: -1;
	}

	.top {
		position: absolute;
		//z-index: 1;
		width: 100%;
		top: $icon-size/2*-1;
		
		h1 {
			margin: 0;
		}		
		
		.icon {
  	height: $icon-size;
  	width: $icon-size;
  	display: inline-block;
  	margin: 0 0 10px 0;
		}
		
	}
	
	.main {
	}

}

.banner-text {
	margin: 30px 0 40px 0;

	.text {
		margin: 0;

			
		h2 {
			font-size: rem-calc(30);
			line-height: rem-calc(30);
		}
		
		p {
			font-style: italic;
		}

	}

}

@include breakpoint(medium) { 

	.section-banner {
		margin: $icon-size*1.2/2 0 0 0;
		min-height: $icon-size*1.2/2 + 30px;
		
		.top {
			top: $icon-size*1.2/2*-1;
			
			h1 {
				margin: ($icon-size*1.2/2)-17px 0 0 0;
			}
			
			.icon {
	  	height: $icon-size*1.2;
	  	width: $icon-size*1.2;
				margin: 0 0 15px 0;
			}
			
		}
		
	}

}

@include breakpoint(large) { 

	.section-banner {
		margin: $icon-size*1.5/2 0 0 0;
		min-height: $icon-size*1.5/2 + 30px;
		
		.top {
			top: $icon-size*1.5/2*-1;
			
			h1 {
				margin: ($icon-size*1.5/2)-22px 0 0 0;
			}
			
			.icon {
	  	height: $icon-size*1.5;
	  	width: $icon-size*1.5;
				margin: 0 0 7px 0;
			}
			
		}
		
	}

	.banner-text {
		margin: 50px 0 0 0;
	
		.text {
				margin: 0 0 50px 0;
				
			h2 {
				float: left;
				font-size: rem-calc(30);
				margin: -6px 7px 0 0;
				line-height: rem-calc(30);
			}
			
		}
		
		.people-item {
			margin-bottom: $people-img-size/2;
		}
		
		.people-item.float {
			position: relative;
			margin-bottom: $people-img-size/2;
			
			.image {
				position: absolute;
				z-index: 1;
				bottom: $people-img-size*-1;
				left: 50%;
				margin-left: $people-img-size/-2;
			}
			
		}
		
	}

}

@include breakpoint(xlarge) { 

	.section-banner {
		margin: $icon-size*2/2 0 0 0;
		min-height: $icon-size*1.8/2 + 30px;
		
		.top {
			top: $icon-size*1.8/2*-1;
			
			h1 {
				margin: ($icon-size*1.8/2)-26px 0 0 0;
			}

			.icon {
	  	height: $icon-size*1.8;
	  	width: $icon-size*1.8;
				margin: 0 0 7px 0;
			}
			
		}
		
	}

}



// 11. Isotope/Masonry
//	--------------------


.isotope-is-container {

 .isotope-is {
 
  /* reveal grid after images loaded */
  &.are-images-unloaded {
    opacity: 0;
  }
 
  /* hide by default */
  &.are-images-unloaded .cell {
    opacity: 0;
  }
 
 }
 
 .page-load-status {
   display: none; /* hidden by default */
   padding-top: 20px;
   border-top: 1px solid transparent;
   text-align: center;
   color: #777;
 }

}

.masonry-il-container {
	margin: 0 0 80px 0;
}

// 12. Section Content
//	--------------------

.section-content {
	padding: 50px 0;
	
	.text {
		margin: 0 0 50px 0;
		
		> ul {
			list-style: none;
			margin: 0;
			
			li {
				
				&:before {
	    @include icomoon;
	    content: '\e903';
					margin: 0 7px 0 0;
					font-size: rem-calc(12);
				}
				
			}
				
		}
			
		.pax {
			margin: 0 0 30px 0;
			
			.icon {
				display: block;
				font-size: rem-calc(40);
				margin: 0 15px 6px 0;
			}
			
			p {
				margin: 0;
			}
			
		}
		
		
	}
	
	.partner-list {
  margin: 15px 0 0 0;
 
  p {
  margin: 0;
  }
  
  a {
   color: $body-font-color;
  }
  
 }

	.room-list {
		
		.teaser-card {
			display: flex;
			text-align: center;
			background: $white;
			margin-bottom: 50px;
			
			.grid-x {
				height: 100%;

				.button {
					margin: 0;
				}

			}
			
			.front {
				color: $dark-green;
				margin: 0 0 20px 0;
				
				.teaser-text {
					padding: 10px 15px 0 15px;

					ul {
						list-style: none;
						margin: 20px auto;
						border-bottom: 1px solid $medium-gold;
						max-width: 90%;
						
						li {
							border-top: 1px solid $medium-gold;
							color: $dark-gold;
							padding: 7px 0;
							font-style: italic;
						}
						
					}

					p {
						font-style: normal;
					}
					
				}
				
			}
		
		}
		
	}
	
	.packages-list {
		
		.product-option {
			text-align: left;
			padding: 10px 0;
			
			.icon {
				text-align: center;
				display: block;
				width: 50px;
				margin: 5px 0 0 0;
				font-size: rem-calc(50);
			}
			
		}
		
		
		.teaser-card {
			display: flex;
			text-align: center;
			background: $white;
			margin-bottom: 50px;
			
			.grid-x {
				height: 100%;
				color: $white;

				.button {
					margin: 0;
				}
									
			}
			
			.front {
				color: $dark-green;
				padding: 30px 15px 0 15px;
				
				.teaser-text {
					
					.icon {
						display: block;
						margin: 0 0 20px 0;
					}
					
					ul {
						list-style: none;
						margin: 20px auto;
						border-bottom: 1px solid $medium-green;
						max-width: 90%;
						
						li {
							border-top: 1px solid $medium-green;
							padding: 7px 0;
							font-style: italic;
						}
						
					}

					p {
						font-style: normal;
					}
					
				}
				
			}
			
			.back {
				padding: 30px 15px 0 15px;
				
				label {
					color: $white;
					font-style: italic;
					font-size: rem-calc(16);
				}
				
				h2 {
					margin: 0 0 20px 0;
				}
				
				h5 {
					display: block;
					margin: 0 0 20px 0;
					text-transform: uppercase;
					font-family: $bebas-font-family;
					letter-spacing: 2px;
				}
					
				ul {
					list-style: none;
					margin: 30px 0;
					display: flex;
			  align-items: center;
			  justify-content: center;
					
					
					li {
						display: inline-block;
						padding: 0 7px;
      align-self: center; 

						.icon {
							font-size: rem-calc(18);
							line-height: rem-calc(30);
						}
						
						.icon-beamer {
							font-size: rem-calc(14);
						}
						
						.icon-screen {
							font-size: rem-calc(22);
						}
						
						.icon-parking {
							font-size: rem-calc(22);
						}

						.icon-monitor {

						}
						
						.icon-kitchen {
 						
						}
						
						.icon-audio {
 						
						}
						
						.icon-coffeebreak {
 						
						}
						
						.icon-flipchart {
							font-size: rem-calc(26);
							line-height: rem-calc(40);
						}
						
					}
					
				}
				
			}
		
		}
		
	}
	
	.plan-list {
	
		.teaser-card {
			text-align: center;
			margin-bottom: 50px;
			
			.image {
				width: 90%;
				max-width: 500px;
				margin: 0 auto;
			}
			
			.teaser-text {
				padding: 10px 15px 30px 15px;
				
				p {
					margin: 0 0 3px 0;
				}
				
			}
		
		}
	
	}

}

@include breakpoint(xlarge) { 

	.section-content {
		padding: 70px 0;
	
		.text {
			margin: 0 0 50px 0;
		}
		
		.room-list {
		
			.teaser-card {
			
				.front {
				
					.teaser-text {
						padding: 10px 25px 0 25px;
					
						ul {
							max-width: 80%;
						}
					
					}
				
				}
				
			}
			
		}

		.plan-list {
		
			.teaser-card {
		
				.image {
					width: 100%;
					max-width: 800px;
				}
	
			}
		
		}
		
		.packages-list {
		
			.teaser-card {
				
				.front {
					padding: 30px 30px 0 30px;
				}
				
				.back {
					padding: 30px 30px 0 30px;
					
					h2 {
						letter-spacing: 3px;
						margin: 0 0 30px 0;
					}
					
				}

			}

		}

	}

}

.section-content {

	.menu-card {
		background: $white;
		padding: 30px 15px;
		
		.grid-x {
			padding: 0 0 25px 0;
		}
		
		h5 {
			font-weight: bold;
			margin: 3px 0;
		}
		
		p {
			margin: 0;
		}
	
	}

}

@include breakpoint(medium) { 

	.section-content {
	
		.menu-card {
			padding: 40px 30px;
		}
	
	}

}

// 13. Section Features
//	--------------------

.section-features {
	padding: 30px 0;
	margin: 0 0 50px 0;
	
	&.no-margin {
		margin: 0;
	}
	
	.features-list {
		color: $dark-gold;
		text-align: center;
			
		a {
			color: $dark-gold;
			
			.icon {
				background: rgba($dark-gold,0.2);
			}
			
		}
		
		.grid-x {
			height: 100%;
		}
		
		p {
			font-size: rem-calc(14);
			font-style: italic;
			margin: 0;
		}
		
		.icon {
			display: block;
			height: 50px;
			width: 50px;
			margin: 0 auto 5px auto;
			border: 1px solid $dark-gold;
			border-radius: 50%;
			position: relative;
			font-size: rem-calc(22);
			
			&.icon-menu {
				font-size: rem-calc(28);
			}
			
			&.icon-lunch {
				font-size: rem-calc(18);
			}

			&.icon-shower {
				font-size: rem-calc(22);
			}

			&.icon-beamer {
				font-size: rem-calc(18);
			}
			
			&.icon-flipchart {
				font-size: rem-calc(30);
			}
			
			&.icon-coffee {
				
			}
			
			&.icon-monitor {
				font-size: rem-calc(22);
			}
			
			&.icon-kitchen {
				font-size: rem-calc(24);
			}
			
			&.icon-audio {
				font-size: rem-calc(24);
			}
			
			&.icon-coffeebreak {
				font-size: rem-calc(30);
			}

			&.icon-wlan {
			font-size: rem-calc(20);
				
			}
			
			&.icon-parking {
			font-size: rem-calc(27);
			
				&:before {
					margin: -2px 0 0 0;
				}
				
			}
			
			&.icon-screen {
			font-size: rem-calc(24);
			}
			
			
			&:before {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
			
		}		

	}
	
}

@include breakpoint(medium) { 
	
	.section-features {
		padding: 30px 0;
		
		.features-list {
			text-align: left;
			
			.icon {
				margin: 0 15px 0 0;
			}
			
		}
		
	}
		
}
		
@include breakpoint(large) { 
	
	.section-features {
		
		.features-list {
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center; 
  }
  
 }

}

@include breakpoint(xlarge) { 
	
	.section-features {
			margin: 0 0 80px 0;
		
		.grid-container {
			//padding: 0 80px;
		}
		
	}
		
}
		
	


// 14. People Item
//	--------------------

.people-item {
	width: $people-item-size;
	margin: 0 auto;
	//height hack
	border: 1px solid transparent;
	
	.text {
			margin: 0 0 30px 0;
			
		h5 {
			font-size: rem-calc(18);
		}

		p {
			font-style: normal;
			font-size: rem-calc(14);
			margin: 0 0 2px 0;
		}

		a {
			color: $dark-green;
			text-decoration: underline;
		}
		
	}
	
	.image {
		max-width: $people-img-size;
		margin: 0 auto;
		border-radius: 50%;
		border: 2px solid $dark-gold;
		overflow: hidden;
		
		img {
			z-index: -1;
		}
		
	}
	
}

// 15. Teaser Footer Grid
//	----------------------

$teaser-footer-size-small: 70;
$teaser-footer-size-medium: 70;
$teaser-footer-size-large: 90;
$teaser-footer-size-xlarge: 90;
$teaser-footer-div-width-small: 100 / $teaser-footer-size-small * 100;
$teaser-footer-translate-small: (100 - $teaser-footer-size-small) / -2;
$teaser-footer-div-width-medium: 100 / $teaser-footer-size-medium * 200;
$teaser-footer-translate-medium: (100 - $teaser-footer-size-medium) / -4;
$teaser-footer-div-width-large: 100 / $teaser-footer-size-large * 400;
$teaser-footer-translate-large: (100 - $teaser-footer-size-large) / -8;
$teaser-footer-div-width-xlarge: 100 / $teaser-footer-size-xlarge * 400;
$teaser-footer-translate-xlarge: (100 - $teaser-footer-size-xlarge) / -8;

.teaser-footer {
	position: relative;

	.caption {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		
		.grid-container {
			height: 100%;
			padding: 0;
			
			.grid-x {
				height: 100%;
			}
			
		}
			
	}	
	
	.teaser-card {
		position: relative;
		overflow: hidden;
		width: $teaser-footer-size-small + 0%;
		padding-bottom: $teaser-footer-size-small + 0%;
		
		.blur {
			position: absolute;
			top: 0;
			width: $teaser-footer-div-width-small + 0%;
			
		}

		&.card-one {
			margin: 0 auto;
			
			.blur img {
				margin-top: $teaser-footer-translate-small + 0%;
				transform: translate($teaser-footer-translate-small + 0%, 0);
			}
			
		}
		
		&.card-two {
			margin: 0 auto;
			
			.blur img {
				margin-top: $teaser-footer-translate-small + 0%;
				transform: translate($teaser-footer-translate-small + 0%, -25%);
			}
			
		}
		
		&.card-three {
			margin: 0 auto;
			
			.blur img {
				margin-top: $teaser-footer-translate-small + 0%;
				transform: translate($teaser-footer-translate-small + 0%, -50%);
			}
			
		}
		
		&.card-four {
			margin: 0 auto;
			
			.blur img {
				margin-top: $teaser-footer-translate-small + 0%;
				transform: translate($teaser-footer-translate-small + 0%, -75%);
			}
	
		}
					
	}

	&.two-up {
	
		.caption {
				
			.teaser-card {
				
				&.card-two {
								
					.blur img { 
							transform: translate($teaser-footer-translate-small + 0%, -50%);
					}
					
				}

			}
		
		}
		
	}

	&.three-up {
	
		.caption {
				
			.teaser-card {
				
				&.card-two {
								
					.blur img { 
							transform: translate($teaser-footer-translate-small + 0%, -33.33334%);
					}
					
				}

				&.card-three {
								
					.blur img { 
							transform: translate($teaser-footer-translate-small + 0%, -66.66667%);
					}
					
				}

			}
		
		}
		
	}

}

@include breakpoint(medium) { 
	
	.teaser-footer {
	
		.teaser-card {
			width: $teaser-footer-size-medium + 0%;
			padding-bottom: $teaser-footer-size-medium + 0%;
			
			.blur {
				width: $teaser-footer-div-width-medium + 0%;
			}
	
			&.card-one {
				margin: 10% 5% -10% auto;
	
				.blur	img {
					margin-top: $teaser-footer-translate-medium - 5 + 0%;
					margin-left: -5%;
					transform: translate($teaser-footer-translate-medium + 0%, 0);
				}
				
			}
			
			&.card-two {
				margin: 10% auto -10% 5%;
				
				.blur	img {
					margin-top: $teaser-footer-translate-medium - 5 + 0%;
					margin-left: -45%;
					transform: translate($teaser-footer-translate-medium + 0%, 0);
				}
				
			}
			
			&.card-three {
				margin: -10% 5% 10% auto;
				
				.blur	img {
					margin-top: $teaser-footer-translate-medium + 5 + 0%;
					margin-left: -5%;
					transform: translate($teaser-footer-translate-medium + 0%, -50%);
				}
				
			}
			
			&.card-four {
				margin: -10% auto 10% 5%;
				
				.blur	img {
					margin-top: $teaser-footer-translate-medium + 5 + 0%;
					margin-left: -45%;
					transform: translate($teaser-footer-translate-medium + 0%, -50%);
				}
	
			}
	
		}
		
		&.one-up {
		
			.caption {
			
				.grid-container {
					
					.grid-x {
						
						.cell.one-offset {
							margin-left: 25%;
						}

					}
				
				}
			
				.teaser-card {
					
					&.card-one {
						margin: 0 auto;
									
						.blur img { 
							margin-top: $teaser-footer-translate-medium + 0%;
							margin-left: -25%;
							transform: translate($teaser-footer-translate-medium + 0%, 0);
						}
						
					}
	
				}
			
			}
			
		}
		
		&.two-up {
		
			.caption {
			
				.grid-container {
					
					.grid-x {
						
					}
				
				}
			
				.teaser-card {
					
					&.card-one {
						margin: 0 5% 0 auto;
									
						.blur img { 
							margin-top: $teaser-footer-translate-medium + 0%;
							margin-left: -5%;
							transform: translate($teaser-footer-translate-medium + 0%, 0);
						}
						
					}
	
					&.card-two {
						margin: 0 auto 0	5%;
									
						.blur img { 
							margin-top: $teaser-footer-translate-medium + 0%;
							margin-left: -45%;
							transform: translate($teaser-footer-translate-medium + 0%, 0);
						}
						
					}
	
				}
			
			}
			
		}
		
		&.three-up {
		
			.caption {
			
				.grid-container {
					
					.grid-x {
						
						.cell.three-offset {
							margin-left: 25%;
						}
						
					}
				
				}
			
				.teaser-card {
					
					&.card-two {
						margin: 10% auto -10% 5%;
									
						.blur img { 
							margin-left: -45%;
							transform: translate($teaser-footer-translate-medium + 0%, 0);
						}
						
					}
	
					&.card-three {
						margin: -10% auto 10% auto;
									
						.blur img { 
							margin-left: -25%;
							transform: translate($teaser-footer-translate-medium + 0%, -50%);
						}
						
					}
	
				}
			
			}
			
		}
		
	}

}

@include breakpoint(large) { 
	
	.teaser-footer {
	
		.teaser-card {
			width: $teaser-footer-size-large + 0%;
			padding-bottom: $teaser-footer-size-large + 0%;
			
			.blur {
				width: $teaser-footer-div-width-large + 0%;
			}
	
			&.card-one {
				margin: 0 auto;
							
				.blur img { 
					margin-top: $teaser-footer-translate-large + 0%;
					margin-left: 0;
					transform: translate($teaser-footer-translate-large + 0%, -25%);
				}
				
			}
			
			&.card-two {
				margin: 0 auto;
				
				.blur img { 
					margin-top: $teaser-footer-translate-large + 0%;
					margin-left: -25%;
					transform: translate($teaser-footer-translate-large + 0%, -25%);
			}
				
			}
			
			&.card-three {
				margin: 0 auto;
				
				.blur img { 
					margin-top: $teaser-footer-translate-large + 0%;
					margin-left: -50%;
					transform: translate($teaser-footer-translate-large + 0%, -25%);
				}
				
			}
			
			&.card-four {
				margin: 0 auto;
				
				.blur img { 
					margin-top: $teaser-footer-translate-large + 0%;
					margin-left: -75%;
					transform: translate($teaser-footer-translate-large + 0%, -25%);
				}
				
			}
			
		}

		&.one-up {
		
			.caption {
			
				.grid-container {
					
					.grid-x {
						
						.cell.one-offset {
							margin-left: 37.5%;
						}

					}
				
				}
			
				.teaser-card {
					
					&.card-one {
						margin: 0 auto;
									
						.blur img { 
							margin-left: -37.5%;
							margin-top: $teaser-footer-translate-large + 0%;
							transform: translate($teaser-footer-translate-large + 0%, -25%);
						}
						
					}
	
				}
			
			}
			
		}
		
		&.two-up {
		
			.caption {
			
				.grid-container {
					
					.grid-x {
						
						.cell.one-offset {
							margin-left: 25%;
						}

					}
				
				}
			
				.teaser-card {
					
					&.card-one {
						margin: 0 auto;
									
						.blur img { 
							margin-left: -25%;
							margin-top: $teaser-footer-translate-large + 0%;
							transform: translate($teaser-footer-translate-large + 0%, -25%);
						}
						
					}
	
					&.card-two {
						margin: 0 auto;
									
						.blur img { 
							margin-top: $teaser-footer-translate-large + 0%;
							margin-left: -50%;
							transform: translate($teaser-footer-translate-large + 0%, -25%);
						}
						
					}
	
				}
			
			}
			
		}
		
		&.three-up {
		
			.caption {
			
				.grid-container {
					
					.grid-x {
						
						.cell.one-offset {
							margin-left: 12.5%;
						}

						.cell.three-offset {
							margin-left: 0;
						}
						
					}
				
				}
			
				.teaser-card {
					
					&.card-one {
						margin: 0 auto;
									
						.blur img { 
							margin-left: -12.5%;
						}
						
					}
	
					&.card-two {
						margin: 0 auto;
									
						.blur img { 
							margin-left: -37.5%;
							transform: translate($teaser-footer-translate-large + 0%, -25%);
						}
						
					}
	
					&.card-three {
						margin: 0 auto;
			
						.blur img { 
							margin-left: -62.5%;
							transform: translate($teaser-footer-translate-large + 0%, -25%);
						}
						
					}
	
				}
			
			}
			
		}
	
	}

}

@media screen and (min-width: $global-width) {
		
	.teaser-footer {

		.teaser-card {
			width: $teaser-footer-size-xlarge + 0%;
			padding-bottom: $teaser-footer-size-xlarge + 0%;
			
			.blur {
				top: 50%;
				width: 100vw;
			}

			&.card-one {
				margin: 0 auto;
							
				.blur img { 
					margin-top: 0;
					margin-left: calc(#{$global-width/2 - 0.75} - 50%);
					transform: translate(0, -50%);
				}
				
			}
			
			&.card-two {
				margin: 0 auto;
			
				.blur img { 
					margin-top: 0;
					margin-left: calc(#{($global-width/2 - 19.6)} - 50%);
					transform: translate(0, -50%);
				}
				
			}
			
			&.card-three {
				margin: 0 auto;
				
				.blur img { 
					margin-top: 0;
					margin-left: calc(#{($global-width/2 - 38.55)} - 50%);
					transform: translate(0, -50%);
				}
				
			}
			
			&.card-four {
				margin: 0 auto;
				
				.blur img { 
					margin-top: 0;
					margin-left: calc(#{($global-width/2 - 57.4)} - 50%);
					transform: translate(-0, -50%);
				}
	
			}
			
		}

		&.one-up {
		
			.caption {
			
				.grid-container {
					
					.grid-x {
						
						.cell.one-offset {
							margin-left: 37.5%;
						}

					}
				
				}
			
				.teaser-card {
					
					&.card-one {
						margin: 0 auto;
									
						.blur img { 
							margin-top: 0;
							margin-left: calc(#{$global-width/2 - 29.1} - 50%);
							transform: translate(0, -50%);
						}
						
					}
	
				}
			
			}
			
		}
		
		&.two-up {
		
			.caption {
			
				.grid-container {
					
					.grid-x {
						
						.cell.one-offset {
							margin-left: 25%;
						}

					}
				
				}
			
				.teaser-card {
					
					&.card-one {
						margin: 0 auto;
									
						.blur img { 
							margin-top: 0;
							margin-left: calc(#{$global-width/2 - 19.55} - 50%);
							transform: translate(0, -50%);
						}
						
					}
	
					&.card-two {
						margin: 0 auto;
									
						.blur img { 
							margin-top: 0;
							margin-left: calc(#{($global-width/2 - 38.55)} - 50%);
							transform: translate(0, -50%);
						}
						
					}
	
				}
			
			}
			
		}
		
		&.three-up {
		
			.caption {
			
				.grid-container {
					
					.grid-x {
						
						.cell.one-offset {
							margin-left: 12.5%;
						}

						.cell.three-offset {
							margin-left: 0;
						}
						
					}
				
				}
			
				.teaser-card {
					
					&.card-one {
						margin: 0 auto;
									
						.blur img { 
							margin-top: 0;
							margin-left: calc(#{$global-width/2 - 10.1} - 50%);
							transform: translate(0, -50%);
						}
						
					}
	
					&.card-two {
						margin: 0 auto;
									
						.blur img { 
							margin-top: 0;
							margin-left: calc(#{($global-width/2 - 29.05)} - 50%);
							transform: translate(0, -50%);
						}
						
					}
	
					&.card-three {
						margin: 0 auto;
			
						.blur img { 
							margin-top: 0;
							margin-left: calc(#{($global-width/2 - 48.05)} - 50%);
							transform: translate(0, -50%);
						}
						
					}
	
				}
			
			}
			
		}
		
	}

}



// 16. Teaser Footer Content
// -------------------------

.teaser-footer {
		position: relative;
		margin: 80px 0 0 0;
		
	.headline {
		position: absolute;
		top: -80px;
		left: 0;
		width: 100%;
		text-align: center;
		z-index: 1;
		color: $dark-gold;
		margin: 25px	0 0 0;
		
		.icon {
			margin: 0 0 45px 0;
			display: flex;
			justify-content: center;
			
			&:before {
				display: block;
				width: fit-content;
				transform: rotate(90deg);
			}
			
		}
		
	}	
	
	
	.image {
		position: relative;
		overflow: hidden;
		
		&:after {
			position: absolute;
			content: '';
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(0,0,0,0.3);
		}
	
		img {
			z-index: -2;
			//opacity: 0.8;
		}
	
	}

	.teaser-card {

		&:after {
			position: absolute;
			content: '';
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(0,0,0,0.3);
		}

		.front, .back {
			text-align: center;
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   transform-style: preserve-3d;
   backface-visibility: hidden;
   transition: all .4s ease-in-out;
			z-index: 1;
 	}

		.front {
   transform: rotateY(0);
			padding: 15px;
			color: $white;
			background: rgba(255,255,255,0.4);
			
			> .grid-x {
				display: flex;
			}
			
			.symbol {
				border: 1px solid $white;
				background: rgba(0,0,0,0.5);
				display: inline-block;
				height: 50px;
				width: 50px;
				border-radius: 50%;
				font-size: rem-calc(27);
				padding: 10px 0 0 0;
				
				&.icon-castle {
					font-size: rem-calc(25);
				}
				
				&.icon-bootle {
					font-size: rem-calc(30);
					padding: 8px 0 0 0;
				}
				
				&.icon-audience {
					font-size: rem-calc(30);
					padding: 8px 0 0 0;
				}
				
				&.icon-catering {
					font-size: rem-calc(27);
				}
				
				&.icon-locationcastle {
					font-size: rem-calc(30);
				}
				
			}
			
			h4 {
				margin: 10px 0 5px 0;
			}
			
			p {
				display: block;
				height: 70px;
				overflow: hidden;
				margin: 0;
			}
			
			a {
			 color: $white;
				display: block;
			}

 	}

		.back {
			text-align: center;
   transform: rotateY(-179deg);
   
			.rel {
				position: relative;
				
				.abs {
					position: absolute;
					width: 100%;
					bottom: 10%;
				}
				
			}
			
			.button {
				margin: 0;
			}
			
  }
  
		&.is-flipped {
			
			.front {
    transform: rotateY(179deg);
		  }
		  
			.back {
	   transform: rotateY(0);
	  }
	  
		}

	}
	
}

@include breakpoint(large) { 

	.teaser-footer {
		margin: 0;
	
		.headline {
			color: $white;
			margin: 45px	0 0 0;
			top: 0;
			
			.icon {
				margin: 0 0 45px	0;
			}
			
		}
	
	}
			
}

@include breakpoint(xxlarge) { 

	.teaser-footer {
	
		.headline {
			margin: 50px	0 0 0;
			
			.icon {
				margin: 0 0 45px	0;
			}
			
		}
	
	}
			
}


// 17. Section Left Right Grid
// ---------------------------

$left-right-size-small: 70;
$left-right-size-medium: 80;
$left-right-size-xmedium: 80;
$left-right-size-large: 70;
$left-right-size-xlarge: 70;
$left-right-div-width-small: 100 / $left-right-size-small * 100;
$left-right-translate-small: (100 - $left-right-size-small) / -2;
$left-right-div-width-medium: 100 / $left-right-size-medium * 200;
$left-right-translate-medium: (100 - $left-right-size-medium) / -4;
$left-right-div-width-xmedium: 100 / $left-right-size-xmedium * 200;
$left-right-translate-xmedium: (100 - $left-right-size-xmedium) / -4;
$left-right-div-width-large: 100 / $left-right-size-large * 200;
$left-right-translate-large: (100 - $left-right-size-large) / -4;
$left-right-div-width-xlarge: 100 / $left-right-size-xlarge * 200;
$left-right-translate-xlarge: (100 - $left-right-size-xlarge) / -4;

.section-left-right {
	
	.content {
		position: relative;

		.caption {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			z-index: 1;
			
			.grid-container {
				height: 100%;
				padding: 0;
				
				.grid-x {
					height: 100%;
					//border: 1px solid aqua;
				}
				
			}
		
		}	
			
		.teaser-card {
			position: relative;
			overflow: hidden;
			width: $left-right-size-small + 0%;
			padding-bottom: $left-right-size-small + 0%;
			
			.blur {
				position: absolute;
				top: 0;
				width: $left-right-div-width-small + 0%;
			}

			&.card-one {
					margin: 0 auto;
							
				.blur img { 
					margin-top: $left-right-translate-small + 0%;
					transform: translate($left-right-translate-small + 0%, 0);
				}
				
			}
			
			&.card-two {
				margin: 0 auto;
			
				.blur img {
					margin-top: $left-right-translate-small + 0%;
					transform: translate($left-right-translate-small + 0%, 0);
				}
				
			}

		}				
		
		.image {
			position: relative;
			overflow: hidden;
			
			&:after {
			position: absolute;
			content: '';
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(0,0,0,0.3);
			}
		
			img {
				z-index: -2;
				//opacity: 0.8;
			}
		
		}
		
	}

}

@include breakpoint(medium) { 
	
	.section-left-right {
	
		.content {
	
			.teaser-card {
				width: $left-right-size-medium + 0%;
				padding-bottom: $left-right-size-medium + 0%;
				
				.blur {
					width: $left-right-div-width-medium * 0.75 + 0%;
				}
		
				&.card-one {
					margin: 0 5% 0 auto;
		
					.blur	img {
						margin-top: $left-right-translate-medium - 1.6 + 0%;
						margin-left: -5%;
						transform: translate($left-right-translate-medium + 0%, 0);
					}
					
				}
				
				&.card-two {
					margin: 0 auto 0 5%;
					
					.blur	img {
						margin-top: $left-right-translate-medium - 1.6 + 0%;
						margin-left: -31.5%;
						transform: translate($left-right-translate-medium + 0%, 0);
					}
					
				}
				
			}
			
		}
	
	}

}

@include breakpoint(xmedium) { 
	
	.section-left-right {
	
		.content {
	
			.teaser-card {
				width: $left-right-size-xmedium + 0%;
				padding-bottom: $left-right-size-xmedium + 0%;
				
				.blur {
					width: $left-right-div-width-xmedium + 0%;
				}
		
				&.card-one {
					margin: 0 5% 0 auto;
		
					.blur	img {
						margin-top: $left-right-translate-xmedium - 8.3 + 0%;
						margin-left: -2.5%;
						transform: translate($left-right-translate-xmedium + 0%, 0);
					}
					
				}
				
				&.card-two {
					margin: 0 auto 0 5%;
					
					.blur	img {
						margin-top: $left-right-translate-xmedium - 8.3 + 0%;
						margin-left: -47.5%;
						transform: translate($left-right-translate-xmedium + 0%, 0);
					}
					
				}
				
			}
			
		}
	
	}

}

@include breakpoint(large) { 
	
	.section-left-right {
	
		.content {
			
			.teaser-card {
				width: $left-right-size-large + 0%;
				padding-bottom: $left-right-size-large + 0%;
				
				.blur {
					width: $left-right-div-width-large + 0%;
				}
		
				&.card-one {
					margin: 0 5% 0 auto;
		
					.blur	img {
						margin-top: $left-right-translate-large + 0%;
						margin-left: -5%;
						transform: translate($left-right-translate-large + 0%, 0);
					}
					
				}
				
				&.card-two {
					margin: 0 auto 0 5%;
					
					.blur	img {
						margin-top: $left-right-translate-large + 0%;
						margin-left: -45%;
						transform: translate($left-right-translate-large + 0%, 0);
					}
					
				}
				
			}
			
		}
	
	}

}

@media screen and (min-width: $global-width) {
		
	.section-left-right {

		.content {

			.teaser-card {
				width: $left-right-size-small + 0%;
				padding-bottom: $left-right-size-small + 0%;
				
				.blur {
					top: 50%;
					width: 100vw;
				}
	
				&.card-one {
					margin: 0 auto;
								
					.blur img { 
						margin-top: 0;
						margin-left: calc(#{$global-width/2 - 5.4} - 50%);
						transform: translate(0, -50%);
					}
					
				}
				
				&.card-two {
					margin: 0 auto;
				
					.blur img { 
						margin-top: 0;
						margin-left: calc(#{($global-width/2 - 43.35)} - 50%);
						transform: translate(0, -50%);
					}
					
				}
								
			}
			
		}
	
	}

}

// 18. Section Left Right Content
// ------------------------------

.section-left-right {

	.content {
		margin: 0 0 30px 0;

		.teaser-card {
		
			&:after {
				position: absolute;
				content: '';
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: rgba(0,0,0,0.3);
			}

			.front {
	   position: absolute;
				z-index: 1;
				//text-align: center;
	   top: 0;
	   left: 0;
	   right: 0;
	   bottom: 0;
				padding: 15px;
				color: $white;
				background: rgba(255,255,255,0.4);
				
				> .grid-x {
					display: flex;
				}
				
				a {
					color: $white;
				}
				
			}
			
		}
		
	}

}

@include breakpoint(xlarge) { 

.section-left-right {

	.content {

		.teaser-card {
		
			.front {
				padding: 15px 30px;
				
				> .grid-x {
					display: flex;
				}
	
			}
			
		}
		
	}

}

}

@include breakpoint(xxlarge) { 

.section-left-right {

	.content {

		.teaser-card {
		
			.front {
				padding: 20px 40px;
				
				> .grid-x {
					display: flex;
				}
	
			}
			
		}
		
	}

}

}


// 19. Section Booking
// -------------------

.section-booking {
	
	.title-area {
		margin: 30px 0 0 0;
	}
	
	.progress-area {
		margin: 0 0 30px 0;
	}
	
	.form-area {
		
		.content {
			margin: 0 0 30px 0;
			padding: 30px 15px;
			
			h5 {
				margin: 0 0 30px 0;
				font-weight: bold;
			}
			
			
		}
		
	}
	
	.button-area {
		margin: 0 0 60px 0;
	}
	
	.sucess-area {
		margin: 30px 0;
			
		.content {
			text-align: center;
			max-width: 700px;
			margin: 0 auto;
			padding: 30px 15px;
			
			h5 {
				font-style: italic;
			}
			
			p {
				font-style: italic;
				font-size: rem-calc(14);
			}
			
		}
				
	}
	
	.result-area {
		margin: 30px 0;
		font-size: rem-calc(14);
			
		.legend {
			min-width: 200px;
			font-weight: bold;
			padding: 0;
		}
		
		.value {
			padding: 0 0 10px 0;
		}
		
	}
	
}


@include breakpoint(medium) { 

	.section-booking {
		
		.result-area {
				
			.legend {
				padding: 5px 0;
			}
			
			.value {
				padding: 5px 0;
			}
			
		}
		
	}

}


@include breakpoint(large) { 

	.section-booking {
		
		.title-area {
			margin: 60px 0 0 0;
		}
		
		.form-area {
			
			.content {
				padding: 30px 30px;
			}
			
		}
	
		.sucess-area {
				
			.content {
				padding: 30px 30px;
				
			}
		
		}

	}

}


@include breakpoint(xlarge) { 

	.section-booking {
		
		.form-area {
			
			.content {
				padding: 30px 45px;
			}
			
		}
	
	}

}



.form {
	
	/* input */
	textarea,
	select,
	input[type="date"],
	input[type="text"] {
		color: $dark-green;
		background-color: $light-stone;
	}

	textarea {
		height: 200px;
	}
	
	input.error,
	textarea.error,
	select.error {
		border: 1px solid red;
	}
	
	/* placeholder */
	input::-webkit-input-placeholder,
	textarea::-webkit-input-placeholder {
		color: $dark-green; 
	}
	
	input:-ms-input-placeholder,
	textarea:-ms-input-placeholder {
		color: $dark-green; 
	}
	
	input::placeholder,
	textarea::placeholder {
		color: $dark-green; 
	}

	/* label */
	label {
		color: $dark-green;
		font-weight: bold;
	}
	
	fieldset { 
		
		.grid-x {
			padding: 0 0 16px 0;
		
			label {
				font-weight: normal;
			}
		
		}
	
	}
		
	/* radio and checkbox base styles */
	input[type="radio"], 
	input[type="checkbox"] {
		height: 1.2em;
		width: 1.2em;
		vertical-align: middle;
		margin: 0 0.4em 0.4em 0;
		border: 1px solid rgba(0, 0, 0, 0.3);
		//background: -webkit-linear-gradient(#FCFCFC, #DADADA);
		background: $white;
		-webkit-appearance: none;
		-webkit-transition: box-shadow 200ms;
		box-shadow:inset 1px 1px 0 #fff, 0 1px 1px rgba(0,0,0,0.1);
	}
	
	/* border radius for radio*/
	input[type="radio"] {
		-webkit-border-radius:100%;
		border-radius:100%;
	}
	
	/* border radius for checkbox */
	input[type="checkbox"] {
		-webkit-border-radius:2px;
		border-radius:2px;
	}
	
	/* hover state */
	input[type="radio"]:not(:disabled):hover,
	input[type="checkbox"]:not(:disabled):hover {
		border-color:rgba(0,0,0,0.5);
		box-shadow:inset 1px 1px 0 #fff, 0 0 4px rgba(0,0,0,0.2);
	}
	
	/* active state */
	input[type="radio"]:active:not(:disabled),
	input[type="checkbox"]:active:not(:disabled) {
		background-image: -webkit-linear-gradient(#C2C2C2, #EFEFEF);
		box-shadow:inset 1px 1px 0 rgba(0,0,0,0.2), inset -1px -1px 0 rgba(255,255,255,0.6);
		border-color:rgba(0,0,0,0.5);
	}
	
	/* focus state */
	input[type="radio"]:focus,
	input[type="checkbox"]:focus {
		outline:none;
		box-shadow:inset 1px 1px 0 #fff, 0 0 4px rgba(0,0,0,0.2);
	}
	
	/* input checked border color */
	input[type="radio"]:checked,
	input[type="checkbox"]:checked {
		border-color:rgba(0, 0, 0, 0.5)
	}
	
	/* radio checked */
	input[type="radio"]:checked:before {
		display: block;
		height: 0.3em;
		width: 0.3em;
		position: relative;
		left: 0.4em;
		top: 0.4em;
		background: rgba(0, 0, 0, 0.7);
		border-radius: 100%;
		content: '';
	}
	
	/* checkbox checked */
	input[type="checkbox"]:checked:before {
		font-weight: bold;
		//color: rgba(0, 0, 0, 0.7);
		color: $dark-green;
		content: '\2713';
		-webkit-margin-start: 0;
		margin-left: 2px;
		font-size: 0.9em;
	}
	
}



.form-small {
	margin: 0 auto 50px auto;
	border: 1px solid $medium-gold;
	max-width: 400px;
		
	.form-content {
		padding: 15px 15px 0 15px;
		color: $white;
	}	
	
	a {
	color: $white;
	}

	.title-area {
		margin: 0 0 15px 0;
	}
	
	.result-area {
		margin: 10px 0 30px 0;
	}
	
	h2 {
		margin: 0;
	}

	h6 {
		font-size: rem-calc(16);
		font-weight: bold;
		margin: 0 0 5px 0;
		
		span {
			font-size: rem-calc(14);
			font-weight: normal;
		}
		
	}
	
	p {
		margin: 0;
	}
	
	hr {
		border-color: $dark-gold;
		margin: 5px 0 10px 0;
	}
	
	.button {
		margin: 0;
	}
	
	label {
		color: $white;
	}
	
}
			

// 20. Nav Progress
// -------------------

.nav-progress {
	position: relative;
	list-style: none;
	margin: 50px 0 0 0;
	
	&.four-steps {
	
		&.step-2 {
			
			&:after {
				right: 62.5%;
			}
			
		}
		
		&.step-3 {
			
			&:after {
				right: 38.5%;
			}
			
		}
		
		&.step-4 {
			
			&:after {
				right: 12.5%;
			}
			
		}
		
	}

	&.three-steps {
	
		&.step-2 {
			
			&:after {
				right: 50%;
			}
			
		}
		
		&.step-3 {
			
			&:after {
				right: 38.5%;
			}
			
		}
		
		&.step-4 {
			
			&:after {
				right: 14.5%;
			}
			
		}
		
	}

	
	li {
		position: relative;
		text-align: center;
		color: $light-green;
		font-size: rem-calc(12);
		margin-bottom: 70px;
				
		&:last-child {
			margin-bottom: 0;
		}
	
		&.is-active {
			color: $dark-gold;
		
			&:before {
				border: 1px solid $dark-gold;
				background: $dark-gold;
			}
			
			&:after {
				color: $white;
			}
			
		}
		
		&.is-checked {
			color: $dark-gold;
			
			&:before {
				border: 1px solid $dark-gold;
			}
			
			&:after {
				color: $dark-gold;
				content: '\2714' !important;
			}
			
		}

		&:before {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 50%;
			width: 40px;
			height: 40px;
			content: '';
			border: 1px solid $light-green;
			border-radius: 50%;
			background: $white;
			margin: -50px 0 0 -20px;
		}
	
		&:after {
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			left: 0;
			height: 40px;
			content: '0';
			margin: -49px 0 0 1px;
			line-height: 40px;
			font-family: $bebas-font-family;
			font-size: rem-calc(18);
		}
		
		&:nth-child(1){
			
			&:after {
				content: '1';
			}
			
		}
		
		&:nth-child(2){
			
			&:after {
				content: '2';
			}
			
		}
		
		&:nth-child(3){
			
			&:after {
				content: '3';
			}
			
		}
		
		&:nth-child(4){
			
			&:after {
				content: '4';
			}
			
		}
	
	}

}

@include breakpoint(medium) { 

	.nav-progress {
		
		li {
			margin-bottom: 0;
		}
		
		&:before {
			position: absolute;
			z-index: 0;
			top: 0;
			height: 3px;
			background: $light-green;
			content: '';
			margin: -30px 0 0 0;
		}
		
		&:after {
			position: absolute;
			top: 0;
			height: 3px;
			background: $dark-gold;
			content: '';
			margin: -30px 0 0 0;
		}
		
		&.four-steps {

			&:before {
				left: 12.5%;
				right: 12.5%;
			}
			
			&:after {
				left: 12.5%;
				right: 87.5%;
			}

		}

		&.three-steps {

			&:before {
				left: 14.5%;
				right: 14.5%;
			}
			
			&:after {
				left: 14.5%;
				right: 85.5%;
			}

		}
		
	}

}


// 21. Veranstaltugen
// ----------

table.calendar {
 table-layout: fixed;
 width: 100%;
}

.calendar-navigation a {
 display: inline-block;
 min-width: 50px;
 color: $dark-green;
}

.calendar-navigation a.left {
 text-align: left;
}

.calendar-navigation a.right {
 text-align: right;
}

.calendar-navigation a i {
 font-size: 30px;
}

.calendar-navigation h3 {
 margin: 0;
}

td.calendar-day-head {
 background: #c3bcac;
 border: none;
 border-right: 1px solid #b4ae9f;
}

td.calendar-day, td.calendar-day-np {
 vertical-align: top;
 padding: 0;
}

td.calendar-day h5 {
 margin: 0;
}

td.calendar-day p {
 margin: 0;
}

td.calendar-day.today		{
 background: #efe7d3;
}


td.calendar-day.disabled		{
 color: $medium-gray;
}

td.calendar-day.valid div.day-number		{
 background: $dark-green;
}

td.calendar-day.valid.disabled div.day-number		{
 background: $medium-gray;
}


div.day-number {
 margin: 0;
}

a.calendar-detail {
 display: block;
 color: $dark-green;
 margin: 3px 0 0 0;
}

a.calendar-detail i {
 display: inline-block;
 margin: 0 5px 0 0;
}

a.calendar-detail span {
 display: inline-block; 
 border-bottom: 1px solid $dark-green;
}

.disabled a.calendar-detail {
 display: none; 
}

.info-veranstaltungen p {
 margin: 0 0 10px 0;
}

.info-veranstaltungen .button-area {
 margin: 0;
}

.veranstaltungen-content {
	padding: 5px 8px;
 min-height: 125px;
 width: calc(100% - 20px);
}

.veranstaltungen-content h5 {
 font-size: 12px;
 line-height: 1.5;
 font-weight: bold;
}

.veranstaltungen-content p {
 font-size: 11px;
 line-height: 1.4;
}

.veranstaltungen-content .alert {
 color: $color-alert;
}

.disabled .veranstaltungen-content .alert {
 color: $medium-gray;
}

.veranstaltungen-content .align-self-bottom p {
 font-size: 11px;
 line-height: 1.3;
}

.veranstaltungen-content .button.tiny {
 min-width: auto;
 width: 100%;
 margin: 0 0 -5px -8px;
 font-size: 11px;
 padding: 6px 10px;
}

.disabled .veranstaltungen-content .button.tiny,
.disabled .veranstaltungen-content .button.tiny:hover {
 background: $medium-gray;
 cursor: default;
}

.form-veranstaltungen {
 height: 100%;
}

.form-veranstaltungen [type=text],
.form-veranstaltungen textarea {
 margin: 0 0 3px 0;
 height: 20px;
 padding: .25rem;
 font-size: 11px;
 font-weight: 400;
 line-height: 1.5;
 color: #0a0a0a;
}

.form-veranstaltungen textarea {
 resize: none; 
 min-height: auto;
 height: 30px;
}

.form-veranstaltungen select {
 margin: 0 0 5px 0;
 padding: 0 .25rem;
 height: 20px;
 font-size: 11px;
 background-position: right 0 center;
}

.form-veranstaltungen .far,
.form-veranstaltungen .fas {
 font-size: 15px;
 color: $dark-gray;
}

.form-veranstaltungen .fa-edit {
	position: absolute;
	bottom: 8px;
	right: 2px;
 font-size: 13px;
}

.form-veranstaltungen .edit-button {
 display: block; 
 position: absolute; 
 height: 20px; 
 width: 20px; 
 right: 0; 
 bottom: 0;
}

.form-veranstaltungen .ajax-loader img {
	width: 40px;
	height: 40px;
}

.form-veranstaltungen { 
	position: relative;
}

.form-veranstaltungen .ajax-loader {
	position: absolute;
	z-index: 10;
	background: rgba(255,255,255, 0.7);
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}
		
.veranstaltungen-mobile .heading {
 border-bottom: 1px solid #1d4b40;
 margin: 30px 0 10px 0;
}

.veranstaltungen-mobile .divider {
 border-bottom: 1px solid #1d4b40;
 margin: 10px 0 10px 0;
}

.veranstaltungen-mobile h5 {
 font-size: 13px;
 font-weight: bold;
 margin: 0;
}

.veranstaltungen-mobile p {
 //margin: 0;
 margin: 15px 0 0 0;
 font-size: 12px;
}

.veranstaltungen-mobile p.date {
 margin: 0;
 font-size: 13px;
}

.veranstaltungen-mobile .button.tiny {
 margin: 15px 0 0 0;
 font-size: 13px;
 padding: 8px 12px;
}

// 29. Footer
// ----------


.footer {
	text-align: center;
	
	.main-footer {
  padding: 50px 0;  
  width: 100%;
	 color: $white;
  background-image: url("../img/pattern.png");
  background-color: $dark-green;
  background-size: 46px 46px;
  
  .grid-x {
   
   .grid-x  {
     height: 100%;
     
    .cell {
     
     &.cell-logo {
      border: none;
      padding: 0;
      text-align: center;
      padding: 0 20px;
     }

     &.cell-adress {
      padding: 50px 20px 0 20px;
     }
      
     &.cell-opening {
      padding: 50px 20px 0 20px;
     }
      
     &.cell-aproach {
      padding: 50px 20px 0 20px;
     }
      
    }
    
   }
   
  }
  
 	a {
 		color: $white;
 	}
 	
 	.icon {
  	display: inline-block;
  	height: $icon-size*1.2;
  	width: $icon-size*1.2;
	  
	  img {
 	  width: 100%;
	  }
	  
 	}
 	
 	.social {
 	 list-style: none;
 	 margin: 30px 0 0 0;
  	display: inline-block;
 	 
 	 li {
  	 display: inline-block;
  	 padding: 0 8px;
  	 
  	 a {
    	font-size: rem-calc(28);
  	 }
  	 
 	 }

 	}
 	
 	h4 {
  	font-family: $bebas-font-family;
  	font-size: rem-calc(20);
  	letter-spacing: 1px;
  	margin: 0 0 20px 0;
 	}
 	
 	p {
  	font-size: rem-calc(14);
  	margin: 0 0 20px 0;
 	}
 	
	}
	
	.sub-footer {
 	padding: 15px 0;
	 color: $medium-green;
	 background: #143b32;
	 text-align: center;
	 font-size: rem-calc(14);
	 
	 a {
 	 text-decoration: underline;
   color: $medium-green;
	 }
	 
	 ul {
 	 list-style: none;
 	 margin: 0;
  	display: inline-block;
 	 
 	 li {
  	 display: inline-block;
  	 padding: 0 10px;
 	 }
 	 
	 }
 	
	}
	
}

@include breakpoint(medium) { 
 
 .footer {
	  text-align: left;
	  
 	.main-footer {

   .grid-x {
    
    .grid-x  {
      
     .cell {
      
      &.cell-logo {
       padding: 0 20px;
      }
 
      &.cell-adress {
       border-left: 1px solid $medium-green;
       padding: 0 20px 0 20px;
      }
       
      &.cell-opening {
       padding: 50px 20px 0 20px;
      }
       
      &.cell-aproach {
       border-left: 1px solid $medium-green;
       padding: 50px 20px 0 20px;
      }
      
     }
     
    }
    
   }
 
  }
 
 }
 
}

@include breakpoint(large) { 
 
 .footer {
 	
 	.main-footer {

   .grid-x {
    
    .grid-x  {
      
     .cell {
      
      &.cell-logo {
      padding: 0 30px 0 30px;
      }
 
      &.cell-adress {
       border-left: 1px solid $medium-green;
      padding: 0 30px 0 30px;
      }
       
      &.cell-opening {
       border-left: 1px solid $medium-green;
       padding: 0 30px 0 30px;
      }
      
      &.cell-aproach {
       border-left: 1px solid $medium-green;
       padding: 0 30px 0 30px;
      }

     }
     
    }
    
   }
 
  }
 
 }
 
}

// 30. Editmode
// ----------

.editmode {
	
	label {
		font-size: rem-calc(12);
		color: $dark-gray;
		margin: 10px 0 0 0;
	}
	
	.toggler-title,
	.toggler-content {
		border-left: 1px solid $light-gray;
		border-right: 1px solid $light-gray;
		border-top: 1px solid $light-gray;
	}
	
	.toggler-content {
  overflow: hidden;
  width: 100%;
  height: 0;
	}
	
	.toggler-content.is-active {
		height: auto;
		overflow: auto;
		border-bottom: 1px solid $light-gray;
	}
	
	.toggler-title {
  padding: 10px;
	}
	
	.toggler-content-panel {
  padding: 10px;
	}
	
	.multihref {
		table,
		table th, 
		table td,
		table tbody th,
		table tbody td,
		table td {
		margin: 0;
		padding: 0;
		border: none;
		font-size: 13px;
		}
		
	}

	.imagecontainer img {
  min-height: 500px;
 }

}

/* ======================= MediaQueries ======================== */

#callbacksDiv {
	position:fixed;
	top:50px;
	left:50px;
	z-index:999;
	font-size: rem-calc(11);
	display: none;
}

.breakpoint-indicator {
	position: absolute;
	padding: 0 15px;
	font-size: rem-calc(11);
	z-index: 999;
	background: none;
	color: $black;
	left: 50%;
	margin-left: -40px;
	width: 80px;
	text-align: center;
	display: none;
}

.breakpoint-indicator:before {
	content:'none';
}

@include breakpoint(small only) { 
// Only affects small screens

	.breakpoint-indicator:before {
		content:'small';
	}
}

@include breakpoint(small) { 
// Only affects small screens and larger
}

@include breakpoint(xsmall down) {
// Only affects xsmall screens and smaller
}

@include breakpoint(xsmall only) { 
// Only affects xsmall screens

	.breakpoint-indicator:before {
		content:'xsmall';
	}
}

@include breakpoint(xsmall) { 
// Only affects xsmall screens and larger
}


@include breakpoint(medium down) {
// Only affects medium screens and smaller
}

@include breakpoint(medium only) { 
// Only affects medium screens

	.breakpoint-indicator:before {
		content:'medium';
	}
}

@include breakpoint(medium) { 
// Only affects medium screens and larger
}


@include breakpoint(xmedium down) {
// Only affects xmedium screens and smaller
}

@include breakpoint(xmedium only) { 
// Only affects xmedium screens

	.breakpoint-indicator:before {
		content:'xmedium';
	}
}

@include breakpoint(xmedium) { 
// Only affects xmedium screens and larger
}


@include breakpoint(large down) {
// Only affects large screens and smaller
}

@include breakpoint(large only) { 
// Only affects large screens

	.breakpoint-indicator:before {
		content:'large';
	}
}

@include breakpoint(large) { 
// Only affects large screens and larger
}

@include breakpoint(xlarge down) {
// Only affects xlarge screens and smaller
}

@include breakpoint(xlarge only) { 
// Only affects xlarge screens

	.breakpoint-indicator:before {
		content:'xlarge';
	}
}

@include breakpoint(xlarge) { 
// Only affects xlarge screens and larger
}

@include breakpoint(xxlarge down) {
// Only affects xxlarge screens and smaller
}

@include breakpoint(xxlarge only) { 
// Only affects xxlarge screens

	.breakpoint-indicator:before {
		content:'xxlarge';
	}
}

@include breakpoint(xxlarge) { 
// Only affects xxlarge screens and larger
}

@include breakpoint(landscape) {
// for landscape-oriented devices only
}

@include breakpoint(retina) {
//for high-resolution displays only
}


/* ======================= /MediaQueries ======================== */


