@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/merriweather/Merriweather-BoldItalic.woff2') format('woff2'),
        url('../fonts/merriweather/Merriweather-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/merriweather/Merriweather-LightItalic.woff2') format('woff2'),
        url('../fonts/merriweather/Merriweather-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/merriweather/Merriweather-Italic.woff2') format('woff2'),
        url('../fonts/merriweather/Merriweather-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/merriweather/Merriweather-Bold.woff2') format('woff2'),
        url('../fonts/merriweather/Merriweather-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/merriweather/Merriweather-BlackItalic.woff2') format('woff2'),
        url('../fonts/merriweather/Merriweather-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/merriweather/Merriweather-Black.woff2') format('woff2'),
        url('../fonts/merriweather/Merriweather-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/merriweather/Merriweather-Regular.woff2') format('woff2'),
        url('../fonts/merriweather/Merriweather-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/merriweather/Merriweather-Light.woff2') format('woff2'),
        url('../fonts/merriweather/Merriweather-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('../fonts/bebas/BebasNeue.woff2') format('woff2'),
        url('../fonts/bebas/BebasNeue.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

