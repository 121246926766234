/*!
 * Icomoon
 * 
 */
 
.icon-btn  {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.2em; } 

.icon-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.icon-xs {
  font-size: .75em; }

.icon-sm {
  font-size: .875em; }
  
.icon-1x {
  font-size: 1em; }

.icon-2x {
  font-size: 2em; }

.icon-3x {
  font-size: 3em; }

.icon-4x {
  font-size: 4em; }

.icon-5x {
  font-size: 5em; }

.icon-6x {
  font-size: 6em; }

.icon-7x {
  font-size: 7em; }

.icon-8x {
  font-size: 8em; }

.icon-9x {
  font-size: 9em; }

.icon-10x {
  font-size: 10em; }

.icon-fw {
  text-align: center;
  width: 1.25em; }

.icon-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .icon-ul > li {
    position: relative; }

.icon-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.icon-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.icon-pull-left {
  float: left; }

.icon-pull-right {
  float: right; }


.icon-spin {
  -webkit-animation: mi-spin 2s infinite linear;
          animation: mi-spin 2s infinite linear; }

.icon-pulse {
  -webkit-animation: mi-spin 1s infinite steps(8);
          animation: mi-spin 1s infinite steps(8); }

@-webkit-keyframes mi-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes mi-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.icon-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.icon-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.icon-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.icon-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

.icon-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1); }

.icon-flip-horizontal.icon-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1); }

:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  -webkit-filter: none;
          filter: none; }

.icon-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em; }

.icon-stack-1x,
.icon-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.icon-stack-1x {
  line-height: inherit; }

.icon-stack-2x {
  font-size: 2em; }

.icon-inverse {
  color: #fff; }

.icon-alarmclock:before {
  content: "\e900";
}
.icon-arrow-down:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e902";
}
.icon-arrow-right:before {
  content: "\e903";
}
.icon-arrow-up:before {
  content: "\e904";
}
.icon-arrowbow-left:before {
  content: "\e905";
}
.icon-arrowbow-right:before {
  content: "\e906";
}
.icon-arrowkey-left:before {
  content: "\e907";
}
.icon-arrowkey-right:before {
  content: "\e908";
}
.icon-audience:before {
  content: "\e909";
}
.icon-audio:before {
  content: "\e90a";
}
.icon-barell:before {
  content: "\e90b";
}
.icon-beamer:before {
  content: "\e90c";
}
.icon-beer:before {
  content: "\e90d";
}
.icon-bottle:before {
  content: "\e90e";
}
.icon-castle:before {
  content: "\e90f";
}
.icon-catering:before {
  content: "\e910";
}
.icon-coffee:before {
  content: "\e911";
}
.icon-coffeebreak:before {
  content: "\e912";
}
.icon-cross:before {
  content: "\e913";
}
.icon-dot:before {
  content: "\e914";
}
.icon-enveloppe:before {
  content: "\e915";
}
.icon-fivestarhouse:before {
  content: "\e916";
}
.icon-flipchart:before {
  content: "\e917";
}
.icon-kitchen:before {
  content: "\e918";
}
.icon-locationcastle:before {
  content: "\e919";
}
.icon-locationmarker:before {
  content: "\e91a";
}
.icon-logo-schlossbraeu:before {
  content: "\e91b";
}
.icon-logo-schlossrued:before {
  content: "\e91c";
}
.icon-logo-storchen:before {
  content: "\e91d";
}
.icon-logo-trotte:before {
  content: "\e91e";
}
.icon-lunch:before {
  content: "\e91f";
}
.icon-menu:before {
  content: "\e920";
}
.icon-monitor:before {
  content: "\e921";
}
.icon-ornament-1:before {
  content: "\e922";
}
.icon-ornament-2:before {
  content: "\e923";
}
.icon-ornament-3:before {
  content: "\e924";
}
.icon-parking:before {
  content: "\e925";
}
.icon-pax:before {
  content: "\e926";
}
.icon-pictures:before {
  content: "\e927";
}
.icon-screen:before {
  content: "\e928";
}
.icon-serve:before {
  content: "\e929";
}
.icon-share-facebook:before {
  content: "\e92a";
}
.icon-share-instagram:before {
  content: "\e92b";
}
.icon-share-twitter:before {
  content: "\e92c";
}
.icon-shower:before {
  content: "\e92d";
}
.icon-speaker:before {
  content: "\e92e";
}
.icon-wine:before {
  content: "\e92f";
}
.icon-wlan:before {
  content: "\e930";
}
.icon-xalendar:before {
  content: "\e931";
}
.icon-home:before {
  content: "\e932";
}

.icon, [class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 }
  
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?u8hs9s');
  src:  url('../fonts/icomoon/icomoon.eot?u8hs9s#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.svg?u8hs9s#icomoon') format('svg'),
    url('../fonts/icomoon/icomoon.ttf?u8hs9s') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?u8hs9s') format('woff');
  font-weight: normal;
  font-style: normal;
}

