.hotelWidget {
    padding: 0 15px;
    max-width: 1140px;
    margin: 0 auto 25px auto;

    @include breakpoint(large) {
        margin: 25px auto 0 auto;
    }

    .spiderBooking {
        background: white;
        //color: #C3B492;
        color: #1d4b40;

        @include breakpoint(large) {
            padding: 10px 0 0 0;
        }

        .spiderBooking-input-block__label {

            label {
                background: none;
                //color: #C3B492;
                color: #1d4b40;
            }

        }

        .spiderDatepicker-input {
            margin: 0;
            //color: #C3B492;
            color: #1d4b40;
        }

    }

}

.hotelWidgetRoom {
    margin-bottom: 15px;
    

    .spiderBooking-input-block__label .label {
        background: none;
    }

    .spiderBooking-input-block__input .input, .spiderBooking-input-block__input .spiderDatepicker-input, .spiderBooking-input-block__input .spiderSelect-single {
        margin: 0;
    }

}